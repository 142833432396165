<template>
  <div
    class="filter-bar bg-cs-light-gray flex flex-col flex-grow-1 flex-shrink-0"
  >
    <div class="flex justify-between items-center p-5 relative">
      <h3 class="text-base font-weight-500">Filters</h3>

      <span class="absolute -right-3.5 z-50">
        <span
          class="
            text-base
            cursor-pointer
            border
            rounded-full
            inline-flex
            w-7
            h-7
            items-center
            justify-center
            bg-white
          "
          @click="$emit('close')"
        >
          <i class="fas fa-angle-left text-gray-700 font-normal text-base"></i>
          <!--         <svg class="svg-icon"
                        style=" transform: rotate(180deg); width: 1rem; height: 1rem;vertical-align: middle;fill: currentColor;overflow: hidden;"
                        version="1.1" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><path
                       d="M880 224H144c-26.5 0-48-21.5-48-48s21.5-48 48-48h736c26.5 0 48 21.5 48 48s-21.5 48-48 48zM144 576h416c26.5 0 48 21.5 48 48s-21.5 48-48 48H144c-26.5 0-48-21.5-48-48s21.5-48 48-48zM144 352h416c26.5 0 48 21.5 48 48s-21.5 48-48 48H144c-26.5 0-48-21.5-48-48s21.5-48 48-48zM880 896H144c-26.5 0-48-21.5-48-48s21.5-48 48-48h736c26.5 0 48 21.5 48 48s-21.5 48-48 48zM917.4 489.4L790.6 362.6c-20.2-20.2-54.6-5.9-54.6 22.6v253.5c0 28.5 34.5 42.8 54.6 22.6l126.7-126.7c12.6-12.5 12.6-32.7 0.1-45.2z"
                       fill="#3a4557"/></svg>-->
        </span>
      </span>
    </div>
    <!--    <hr class="m-0">-->
    <div class="overflow-y-auto p-5 pt-0">
      <div
        v-if="!isGridView"
        class="
          flex flex-row
          items-center
          justify-between
          cursor-pointer
          -mx-5
          pr-5
          my-2
        "
      >
        <FilterDropdown
          ref="filter-dropdown"
          :filters="savedFilters"
          :current-filter="currentFilter._id"
          :current-filter-modified="currentFilterModified"
          class="pl-5"
          size="small"
          @handle-apply="handleApply(null)"
          @fetch-plans="$emit('fetch-plans')"
          @implement-filter="implementFilter"
          @save-filter="saveFilter"
          @set-default-filter-status="setDefaultFilterStatus"
          @remove-saved-filter="removeSavedFilter"
        />
        <!-- Expand and Check Box-->
        <div class="flex flex-row">
          <div
            class="
              px-1
              py-0.5
              flex
              items-center
              justify-center
              bg-cs-secondary
              cursor-pointer
              rounded-lg
            "
          >
            <i
              :class="{ 'fa-rotate-180': !isOpen }"
              class="fas fa-chevron-up transition text-gray-800 text-xs"
              @click="toggleAllCollapsible"
            ></i>
          </div>
          <label class="m-0 p-0 d-flex justify-between items-center">
            <input
              :checked="allFiltersChecked()"
              class="prevent-toggle check-input opacity-0 absolute h-8 w-8"
              type="checkbox"
              @change="(event) => onSelectAll(event)"
            />

            <div
              class="
                prevent-toggle
                relative
                cursor-pointer
                bg-white
                rounded-md
                w-5
                h-5
                flex flex-shrink-0
                justify-center
                items-center
                ml-2
                focus-within:border-blue-500
              "
            >
              <svg
                class="
                  fill-current
                  hidden
                  w-2.5
                  h-2.5
                  text-blue-500
                  pointer-events-none
                "
                version="1.1"
                viewBox="0 0 17 12"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g fill="none" fill-rule="evenodd">
                  <g
                    fill="#0088cc"
                    fill-rule="nonzero"
                    transform="translate(-9 -11)"
                  >
                    <path
                      d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z"
                    />
                  </g>
                </g>
              </svg>
            </div>
          </label>
        </div>
      </div>

      <!--   Status   -->
      <div
        :class="collapsable['status'] ? null : 'collapsed'"
        class="title-header"
        @click="(e) => handleCollapse('status', e)"
      >
        <div class="flex-1 pl-5 py-3.5">
          <span class="font-weight-500">Status</span>
        </div>
        <div>
          <div class="relative m-0 hover:text-black-900 flex">
            <span class="text-xs">
              <i
                class="
                  far
                  fa-chevron-up
                  text-gray-700
                  w-4
                  h-4
                  inline-flex
                  justify-center
                  items-center
                "
              ></i>
            </span>
            <label class="m-0 p-0 d-flex justify-between items-center">
              <input
                class="prevent-toggle check-input opacity-0 absolute h-8 w-8"
                :checked="statuses.length === 9"
                type="checkbox"
                @change="(event) => handleSelectAll(event, 'statuses')"
              />

              <div
                class="
                  prevent-toggle
                  relative
                  cursor-pointer
                  bg-white
                  rounded-md
                  w-5
                  h-5
                  flex flex-shrink-0
                  justify-center
                  items-center
                  ml-2
                  focus-within:border-blue-500
                "
              >
                <svg
                  class="
                    fill-current
                    hidden
                    w-2.5
                    h-2.5
                    text-blue-500
                    pointer-events-none
                  "
                  version="1.1"
                  viewBox="0 0 17 12"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill="none" fill-rule="evenodd">
                    <g
                      transform="translate(-9 -11)"
                      fill="#0088cc"
                      fill-rule="nonzero"
                    >
                      <path
                        d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z"
                      />
                    </g>
                  </g>
                </svg>
              </div>
            </label>
          </div>
        </div>
      </div>
      <b-collapse id="status-collapse" v-model="collapsable['status']" visible>
        <div class="py-1.5">
          <div class="py-2">
            <SimpleCheckbox v-model="statuses" class="group" name="in_progress">
              <template v-slot:label>
                <span class="mr-2.5">
                  <img
                    width="20"
                    height="20"
                    src="@assets/img/icons/statuses/in_progress.svg"
                    alt="in_progress"
                  />
                </span>
                <span>In Progress</span>
                <span
                  class="
                    ml-2
                    text-xs
                    font-normal
                    border
                    rounded-md
                    bg-gray-400
                    hover:bg-gray-500
                    px-1
                    hidden
                    group-hover:inline-flex
                    absolute
                    right-3
                  "
                  @click.stop.prevent="
                    handleOnlyFilter('statuses', 'in_progress')
                  "
                  >only</span
                >
              </template>
              <template v-slot:count>
                {{ plansCount.in_progress ?? 0 }}
              </template>
            </SimpleCheckbox>
          </div>
          <div class="py-2">
            <SimpleCheckbox v-model="statuses" class="group" name="scheduled">
              <template v-slot:label>
                <span class="mr-2.5">
                  <img
                    width="20"
                    height="20"
                    src="@assets/img/icons/statuses/scheduled.svg"
                    alt="scheduled"
                  />
                </span>
                <span>Scheduled</span>
                <span
                  class="
                    ml-2
                    text-xs
                    font-normal
                    border
                    rounded-md
                    bg-gray-400
                    hover:bg-gray-500
                    px-1
                    hidden
                    group-hover:inline-flex
                    absolute
                    right-3
                  "
                  @click.stop.prevent="
                    handleOnlyFilter('statuses', 'scheduled')
                  "
                  >only</span
                >
              </template>
              <template v-slot:count>
                {{ plansCount.scheduled }}
              </template>
            </SimpleCheckbox>
          </div>
          <div class="py-2">
            <SimpleCheckbox v-model="statuses" class="group" name="published">
              <template v-slot:label>
                <span class="mr-2.5">
                  <img
                    width="20"
                    height="20"
                    src="@assets/img/icons/statuses/published_new.svg"
                    alt="published"
                  />
                </span>
                <span>Published</span>
                <span
                  class="
                    ml-2
                    text-xs
                    font-normal
                    border
                    rounded-md
                    bg-gray-400
                    hover:bg-gray-500
                    px-1
                    hidden
                    group-hover:inline-flex
                    absolute
                    right-3
                  "
                  @click.stop.prevent="
                    handleOnlyFilter('statuses', 'published')
                  "
                  >only</span
                >
              </template>
              <template v-slot:count>
                {{ plansCount.published }}
              </template>
            </SimpleCheckbox>
          </div>
          <div class="py-2">
            <SimpleCheckbox v-model="statuses" class="group" name="partial">
              <template v-slot:label>
                <span class="mr-2.5">
                  <img
                    width="20"
                    height="20"
                    src="@assets/img/icons/statuses/partially_failed.svg"
                    alt="partially failed"
                  />
                </span>
                <span>Partially Failed</span>
                <span
                  class="
                    ml-2
                    text-xs
                    font-weight-500
                    border
                    rounded-md
                    bg-gray-400
                    px-1
                    hidden
                    group-hover:inline-flex
                    absolute
                    right-3
                  "
                  @click.stop.prevent="handleOnlyFilter('statuses', 'partial')"
                  >only</span
                >
              </template>
              <template v-slot:count>
                {{ plansCount.partially_failed }}
              </template>
            </SimpleCheckbox>
          </div>
          <div class="py-2">
            <SimpleCheckbox v-model="statuses" class="group" name="failed">
              <template v-slot:label>
                <span class="mr-2.5">
                  <img
                    width="20"
                    height="20"
                    src="@assets/img/icons/statuses/failed.svg"
                    alt="failed"
                  />
                </span>
                <span>Failed</span>
                <span
                  class="
                    ml-2
                    text-xs
                    font-normal
                    border
                    rounded-md
                    bg-gray-400
                    hover:bg-gray-500
                    px-1
                    hidden
                    group-hover:inline-flex
                    absolute
                    right-3
                  "
                  @click.stop.prevent="handleOnlyFilter('statuses', 'failed')"
                  >only</span
                >
              </template>
              <template v-slot:count>
                {{ plansCount.failed }}
              </template>
            </SimpleCheckbox>
          </div>
          <div class="py-2">
            <SimpleCheckbox v-model="statuses" class="group" name="rejected">
              <template v-slot:label>
                <span class="mr-2.5">
                  <img
                    width="20"
                    height="20"
                    src="@assets/img/icons/statuses/rejected.svg"
                    alt="rejected"
                  />
                </span>
                <span>Rejected</span>
                <span
                  class="
                    ml-2
                    text-xs
                    font-normal
                    border
                    rounded-md
                    bg-gray-400
                    hover:bg-gray-500
                    px-1
                    hidden
                    group-hover:inline-flex
                    absolute
                    right-3
                  "
                  @click.stop.prevent="handleOnlyFilter('statuses', 'rejected')"
                  >only</span
                >
              </template>
              <template v-slot:count>
                {{ plansCount.rejected }}
              </template>
            </SimpleCheckbox>
          </div>
          <div class="py-2">
            <SimpleCheckbox
              v-model="statuses"
              class="group"
              name="under_review"
            >
              <template v-slot:label>
                <span class="mr-2.5">
                  <img
                    width="20"
                    height="20"
                    src="@assets/img/icons/statuses/under_review.svg"
                    alt="under_review"
                  />
                </span>
                <span>In Review</span>
                <span
                  class="
                    ml-2
                    text-xs
                    font-normal
                    border
                    rounded-md
                    bg-gray-400
                    hover:bg-gray-500
                    px-1
                    hidden
                    group-hover:inline-flex
                    absolute
                    right-3
                  "
                  @click.stop.prevent="
                    handleOnlyFilter('statuses', 'under_review')
                  "
                  >only</span
                >
              </template>
              <template v-slot:count>
                {{ plansCount.review }}
              </template>
            </SimpleCheckbox>
          </div>
          <div class="py-2">
            <SimpleCheckbox
              v-model="statuses"
              class="group"
              name="missed_review"
            >
              <template v-slot:label>
                <span class="mr-2.5">
                  <img
                    width="18"
                    height="18"
                    src="@assets/img/icons/statuses/missed_review.svg"
                    alt="missed_review"
                  />
                </span>
                <span>Missed Review</span>
                <span
                  class="
                    ml-2
                    text-xs
                    font-normal
                    border
                    rounded-md
                    bg-gray-400
                    hover:bg-gray-500
                    px-1
                    hidden
                    group-hover:inline-flex
                    absolute
                    right-3
                  "
                  @click.stop.prevent="
                    handleOnlyFilter('statuses', 'missed_review')
                  "
                  >only</span
                >
              </template>
              <template v-slot:count>
                {{ plansCount.missed }}
              </template>
            </SimpleCheckbox>
          </div>
          <div class="py-2">
            <SimpleCheckbox v-model="statuses" class="group" name="draft">
              <template v-slot:label>
                <span class="mr-2.5">
                  <img
                    width="22"
                    height="22"
                    src="@assets/img/icons/statuses/draft.svg"
                    alt="draft"
                  />
                </span>
                <span>Draft</span>
                <span
                  class="
                    ml-2
                    text-xs
                    font-normal
                    border
                    rounded-md
                    bg-gray-400
                    hover:bg-gray-500
                    px-1
                    hidden
                    group-hover:inline-flex
                    absolute
                    right-3
                  "
                  @click.stop.prevent="handleOnlyFilter('statuses', 'draft')"
                  >only</span
                >
              </template>
              <template v-slot:count>
                {{ plansCount.draft }}
              </template>
            </SimpleCheckbox>
          </div>
        </div>
      </b-collapse>
      <!-- <hr > -->

      <!--   Social Accounts   -->
      <div
        class="title-header"
        :class="collapsable['accounts'] ? null : 'collapsed'"
        @click="(e) => handleCollapse('accounts', e)"
      >
        <div class="flex-1 pl-5 py-3.5">
          <span class="font-weight-500">Accounts</span>
        </div>
        <div>
          <div class="cursor-pointer relative m-0 hover:text-black-900 flex">
            <span class="text-xs">
              <i
                class="
                  far
                  fa-chevron-up
                  text-gray-700
                  w-4
                  h-4
                  inline-flex
                  justify-center
                  items-center
                "
              ></i>
            </span>
            <label
              v-if="!isGridView"
              class="m-0 p-0 d-flex justify-between items-center"
            >
              <input
                :checked="
                  selectedSocialAccounts.length ===
                    connectedSocialAccountsList.length &&
                  noAccount === true &&
                  connectedSocialAccountsList.length !== 0
                "
                :disabled="connectedSocialAccountsList.length === 0"
                class="prevent-toggle check-input opacity-0 absolute h-8 w-8"
                type="checkbox"
                @change="(event) => handleSelectAll(event, 'social_accounts')"
              />

              <div
                :class="{
                  'grayscale filter cursor-not-allowed':
                    connectedSocialAccountsList.length === 0,
                }"
                class="
                  prevent-toggle
                  relative
                  cursor-pointer
                  bg-white
                  rounded-md
                  w-5
                  h-5
                  flex flex-shrink-0
                  justify-center
                  items-center
                  ml-2
                  focus-within:border-blue-500
                "
              >
                <svg
                  class="
                    fill-current
                    hidden
                    w-2.5
                    h-2.5
                    text-blue-500
                    pointer-events-none
                  "
                  version="1.1"
                  viewBox="0 0 17 12"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill="none" fill-rule="evenodd">
                    <g
                      fill="#0088cc"
                      fill-rule="nonzero"
                      transform="translate(-9 -11)"
                    >
                      <path
                        d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z"
                      />
                    </g>
                  </g>
                </svg>
              </div>
            </label>
          </div>
        </div>
      </div>
      <b-collapse
        id="accounts-collapse"
        v-model="collapsable['accounts']"
        visible
      >
        <div class="py-1.5">
          <div
            v-if="
              isGridView &&
              getFilteredSocialAccounts(
                connectedSocialAccountsList,
                $route.name,
              ).length < 1
            "
            class="p-4"
          >
            <EmptyState
              :heading="`No ${
                $route.name?.includes('tiktok') ? 'Tiktok' : 'Instagram'
              } Accounts Found`"
              button-text="Connect account"
              :action="`/${
                $store.getters.getActiveWorkspace.slug
              }/settings/social/${
                $route.name?.includes('tiktok') ? 'tiktok' : 'instagram'
              }`"
            />
          </div>
          <SimpleCheckbox
            v-if="!isGridView"
            v-model="noAccount"
            v-tooltip.right="{
              content: 'Draft posts may have no account(s) selected yet.',
            }"
            class="py-2 group"
          >
            <template v-slot:label>
              <span class="flex items-center">
                <span class="relative">
                  <span
                    class="bg-gray-500 rounded-full block relative"
                    style="width: 30px; height: 30px"
                  >
                    <span
                      class="
                        rounded-full
                        w-4
                        h-4
                        absolute
                        -right-0.5
                        -bottom-0.5
                        flex
                        justify-center
                        items-center
                        bg-white
                      "
                    >
                      <svg
                        fill="none"
                        height="14"
                        viewBox="0 0 304 304"
                        width="14"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M152 303.55C199.117 303.527 243.539 281.593 272.2 244.202C300.864 206.807 310.505 158.21 298.29 112.702C286.071 67.2021 253.388 29.9681 209.849 11.9621C166.31 -6.04593 116.876 -2.77994 76.079 20.8019C35.29 44.3799 7.78603 85.5868 1.66103 132.302C-4.46007 179.017 11.4891 225.923 44.821 259.222C73.227 287.667 111.798 303.62 151.991 303.55L152 303.55ZM232.512 232.515C213.508 251.472 188.41 263.097 161.664 265.324C134.914 267.551 108.238 260.238 86.363 244.683L244.583 86.2231C260.188 108.098 267.54 134.797 265.329 161.578C263.118 188.355 251.489 213.488 232.509 232.512L232.512 232.515ZM71.492 71.4951C90.492 52.5381 115.59 40.9131 142.34 38.6861C169.09 36.4595 195.766 43.7681 217.641 59.3231L59.421 217.833C43.8 195.954 36.444 169.247 38.655 142.454C40.8659 115.661 52.503 90.5201 71.495 71.4971L71.492 71.4951Z"
                          fill="#FF001B"
                        />
                      </svg>
                    </span>
                  </span>
                </span>
                <span class="flex flex-col ml-3">
                  <span
                    class="
                      block
                      text-sm text-ellipsis
                      font-normal
                      text-black-100
                      select-none
                    "
                  >
                    No Accounts
                  </span>
                  <span
                    class="
                      ml-2
                      text-xs
                      font-normal
                      border
                      rounded-md
                      bg-gray-400
                      hover:bg-gray-500
                      px-1
                      hidden
                      group-hover:inline-flex
                      absolute
                      right-3
                    "
                    @click.stop.prevent="handleOnlyFilter('noAccount', false)"
                    >only</span
                  >
                </span>
              </span>
            </template>
          </SimpleCheckbox>
          <div
            v-for="(account, i) in getFilteredSocialAccounts(
              connectedSocialAccountsList,
              $route.name,
            )"
            :key="i"
            class="py-2"
          >
            <SimpleCheckbox
              v-if="
                account['platform_type'] &&
                account['platform_type'] === 'blog' &&
                !isGridView
              "
              v-model="selectedSocialAccounts"
              :name="account['platform_identifier']"
              class="group"
            >
              <template v-slot:label>
                <span class="flex items-center">
                  <span class="relative">
                    <img
                      :src="account.platform_logo"
                      alt=""
                      class="rounded-full object-cover border"
                      data-fallback="0"
                      height="30"
                      width="30"
                      @error="onError($event, account.platform_logo)"
                    />

                    <img
                      v-if="account.integration === 'wordpress'"
                      src="https://storage.googleapis.com/lumotive-web-storage/default/wordpress.png"
                      alt=""
                      class="absolute -right-0.5 -bottom-0.5 w-4 h-4"
                    />

                    <img
                      v-else
                      :src="
                        require('@assets/img/integration/' +
                          getBlogImage(account.integration))
                      "
                      alt=""
                      class="absolute -right-2 bottom-[-0.35rem] h-[20px]"
                      :class = "{ 'bg-white rounded-full': account?.integration === 'twitter' }"
                    />
                  </span>
                  <span class="flex flex-col ml-3">
                    <span
                      v-tooltip="{
                        content: tooltipContent(account.platform_name, 12),
                      }"
                      class="text-sm font-normal text-black-100 select-none"
                      style="width: 6rem"
                    >
                      {{ truncateString(account.platform_name, 12) }}
                    </span>

                    <span class="block text-xs select-none text-gray-700">
                      Blog
                    </span>
                  </span>
                  <span
                    class="
                      ml-2
                      text-xs
                      font-normal
                      border
                      rounded-md
                      bg-gray-400
                      hover:bg-gray-500
                      px-1
                      hidden
                      group-hover:inline-flex
                      absolute
                      right-3
                    "
                    @click.stop.prevent="
                      handleOnlyFilter(
                        'selectedSocialAccounts',
                        account['platform_identifier'],
                      )
                    "
                    >only</span
                  >
                </span>
              </template>
            </SimpleCheckbox>
            <SimpleCheckbox
              v-else
              v-model="selectedSocialAccounts"
              :name="account[account.channel_identifier]"
              class="group"
            >
              <template v-slot:label>
                <span class="flex items-center">
                  <span
                    class="relative w-[30px] h-[30px] img-border rounded-full"
                  >
                  <!--
                    NOTE: browser preserve intrinsic aspect ratio so if an image has not 1:1,
                    we must explicitly specify max to preserve 30x30
                  -->
                    <img
                      :src="channelImage(account, account.channel_type)"
                      alt=""
                      class="rounded-full object-cover"
                      data-fallback="0"
                      height="30"
                      width="30"
                      max-height="30"
                      max-width="30"
                      @error="
                        $event.target.src =
                          'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
                      "
                    />

                    <img
                      :src="
                        require('@assets/img/integration/' +
                          getSocialImage(account.channel_type))
                      "
                      alt=""
                      class="absolute -right-2 bottom-[-0.35rem] h-[20px]"
                      :class = "{ 'bg-white rounded-full': account?.channel_type === 'twitter' }"
                    />
                  </span>
                  <span class="flex flex-col ml-3">
                    <span class="flex flex-row">
                      <span
                        v-tooltip="{
                          content: tooltipContent(
                            'platform_name' in account
                              ? account.platform_name
                              : account.name,
                            12,
                          ),
                        }"
                        class="
                          text-sm text-ellipsis
                          font-normal
                          text-black-100
                          select-none
                        "
                        style="width: 6rem"
                      >
                        {{
                          truncateString(
                            'platform_name' in account
                              ? account.platform_name
                              : account.name,
                            12,
                          )
                        }}
                      </span>
                      <template v-if="isPremiumTwitter(account)">
                        <img
                          v-tooltip="'X (Twitter) Premium account'"
                          src="@src/assets/img/composer/premium-badge-icon.svg"
                          alt="premium badge"
                          class="mr-2"
                        />
                      </template>
                    </span>

                    <span class="block text-xs select-none text-gray-800">
                      {{ 'type' in account ? account.type : 'Profile' }}
                    </span>
                  </span>
                  <span
                    v-if="!isGridView"
                    class="
                      ml-2
                      text-xs
                      font-normal
                      border
                      rounded-md
                      bg-gray-400
                      hover:bg-gray-500
                      px-1
                      hidden
                      group-hover:inline-flex
                      absolute
                      right-3
                    "
                    @click.stop.prevent="
                      handleOnlyFilter(
                        'selectedSocialAccounts',
                        account[account.channel_identifier],
                      )
                    "
                    >only</span
                  >
                </span>
              </template>
            </SimpleCheckbox>
          </div>
        </div>
      </b-collapse>

      <!--   Assign to   -->
      <div
        v-if="!isGridView"
        class="title-header"
        :class="collapsable['members'] ? null : 'collapsed'"
        @click="(e) => handleCollapse('members', e)"
      >
        <div class="flex-1 pl-5 py-3.5">
          <span class="font-weight-500">Assigned To</span>
        </div>
        <div>
          <div class="relative m-0 hover:text-black-900 flex">
            <span class="text-xs">
              <i
                class="
                  far
                  fa-chevron-up
                  text-gray-700
                  w-4
                  h-4
                  inline-flex
                  justify-center
                  items-center
                "
              ></i>
            </span>
            <label class="m-0 p-0 d-flex justify-between items-center">
              <input
                :checked="
                  selectedAssignedMembers.length === membersList.length &&
                  membersList.length !== 0
                "
                class="prevent-toggle check-input opacity-0 absolute h-8 w-8"
                :disabled="membersList.length === 0"
                type="checkbox"
                @change="(event) => handleSelectAll(event, 'assigned_members')"
              />

              <div
                :class="{
                  'grayscale filter cursor-not-allowed':
                    membersList.length === 0,
                }"
                class="
                  prevent-toggle
                  relative
                  cursor-pointer
                  bg-white
                  rounded-md
                  w-5
                  h-5
                  flex flex-shrink-0
                  justify-center
                  items-center
                  ml-2
                  focus-within:border-blue-500
                "
              >
                <svg
                  class="
                    fill-current
                    hidden
                    w-2.5
                    h-2.5
                    text-blue-500
                    pointer-events-none
                  "
                  version="1.1"
                  viewBox="0 0 17 12"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill="none" fill-rule="evenodd">
                    <g
                      transform="translate(-9 -11)"
                      fill="#0088cc"
                      fill-rule="nonzero"
                    >
                      <path
                        d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z"
                      />
                    </g>
                  </g>
                </svg>
              </div>
            </label>
          </div>
        </div>
      </div>
      <b-collapse
        v-if="!isGridView"
        id="assigned-collapse"
        v-model="collapsable['members']"
        visible
      >
        <div class="py-1.5">
          <div v-for="(member, k) in membersList" :key="k" class="py-2">
            <SimpleCheckbox
              v-model="selectedAssignedMembers"
              :name="member.user._id"
              class="group"
            >
              <template v-slot:label>
                <span class="flex items-center">
                  <span class="relative">
                    <img
                      :src="
                        member.user.image
                          ? member.user.image
                          : `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
                      "
                      alt=""
                      class="rounded-full object-cover border"
                      data-fallback="0"
                      height="30"
                      width="30"
                      @error="onError($event, member.user.image)"
                    />
                  </span>
                  <span class="flex flex-col ml-3">
                    <span
                      v-tooltip="{
                        content: tooltipContent(
                          member.user.full_name.trim().length
                            ? member.user.full_name
                            : member.user.email,
                          11,
                        ),
                      }"
                      class="text-sm font-normal text-black-100 select-none"
                      style="width: 6rem"
                    >
                      {{
                        truncateString(
                          member.user.full_name.trim().length
                            ? member.user.full_name
                            : member.user.email,
                          11,
                        )
                      }}
                    </span>
                    <span class="text-xs select-none text-blue-300">
                      {{ getMutatedUserRole(member.role) }}
                    </span>
                  </span>
                  <span
                    class="
                      ml-2
                      text-xs
                      font-normal
                      border
                      rounded-md
                      bg-gray-400
                      hover:bg-gray-500
                      px-1
                      hidden
                      group-hover:inline-flex
                      absolute
                      right-3
                    "
                    @click.stop.prevent="
                      handleOnlyFilter(
                        'selectedAssignedMembers',
                        member.user._id,
                      )
                    "
                    >only</span
                  >
                </span>
              </template>
            </SimpleCheckbox>
          </div>
        </div>
      </b-collapse>

      <!--   Created by   -->
      <div
        v-if="!isGridView"
        class="title-header"
        :class="collapsable['created_by'] ? null : 'collapsed'"
        @click="(e) => handleCollapse('created_by', e)"
      >
        <div class="flex-1 pl-5 py-3.5">
          <span class="font-weight-500">Created By</span>
        </div>
        <div>
          <div class="relative m-0 hover:text-black-900 flex">
            <span class="text-xs">
              <i
                class="
                  far
                  fa-chevron-up
                  text-gray-700
                  w-4
                  h-4
                  inline-flex
                  justify-center
                  items-center
                "
              ></i>
            </span>
            <label class="m-0 p-0 d-flex justify-between items-center">
              <input
                :checked="
                  selectedCreatedMembers.length === membersList.length &&
                  membersList.length !== 0
                "
                class="prevent-toggle check-input opacity-0 absolute h-8 w-8"
                :disabled="membersList.length === 0"
                type="checkbox"
                @change="(event) => handleSelectAll(event, 'created_members')"
              />

              <div
                :class="{
                  'grayscale filter cursor-not-allowed':
                    membersList.length === 0,
                }"
                class="
                  prevent-toggle
                  relative
                  cursor-pointer
                  bg-white
                  rounded-md
                  w-5
                  h-5
                  flex flex-shrink-0
                  justify-center
                  items-center
                  ml-2
                  focus-within:border-blue-500
                "
              >
                <svg
                  class="
                    fill-current
                    hidden
                    w-2.5
                    h-2.5
                    text-blue-500
                    pointer-events-none
                  "
                  version="1.1"
                  viewBox="0 0 17 12"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill="none" fill-rule="evenodd">
                    <g
                      transform="translate(-9 -11)"
                      fill="#0088cc"
                      fill-rule="nonzero"
                    >
                      <path
                        d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z"
                      />
                    </g>
                  </g>
                </svg>
              </div>
            </label>
          </div>
        </div>
      </div>
      <b-collapse
        v-if="!isGridView"
        id="created-collapse"
        v-model="collapsable['created_by']"
        visible
      >
        <div class="py-1.5">
          <div v-for="(member, k) in membersList" :key="k" class="py-2">
            <SimpleCheckbox
              v-model="selectedCreatedMembers"
              :name="member.user._id"
              class="group"
            >
              <template v-slot:label>
                <span class="flex items-center">
                  <span class="relative">
                    <img
                      :src="
                        member.user.image
                          ? member.user.image
                          : `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
                      "
                      alt=""
                      class="rounded-full object-cover border"
                      data-fallback="0"
                      height="30"
                      width="30"
                      @error="onError($event, member.user.image)"
                    />
                  </span>
                  <span class="flex flex-col ml-3">
                    <span
                      v-tooltip="{
                        content: tooltipContent(
                          member.user.full_name.trim().length
                            ? member.user.full_name
                            : member.user.email,
                          11,
                        ),
                      }"
                      class="text-sm font-normal text-black-100 select-none"
                      style="width: 6rem"
                    >
                      {{
                        truncateString(
                          member.user.full_name.trim().length
                            ? member.user.full_name
                            : member.user.email,
                          11,
                        )
                      }}
                    </span>

                    <span class="text-xs text-blue-300 select-none">
                      {{ getMutatedUserRole(member.role) }}
                    </span>
                  </span>
                  <span
                    class="
                      ml-2
                      text-xs
                      font-normal
                      border
                      rounded-md
                      bg-gray-400
                      hover:bg-gray-500
                      px-1
                      hidden
                      group-hover:inline-flex
                      absolute
                      right-3
                    "
                    @click.stop.prevent="
                      handleOnlyFilter(
                        'selectedCreatedMembers',
                        member.user._id,
                      )
                    "
                    >only</span
                  >
                </span>
              </template>
            </SimpleCheckbox>
          </div>
        </div>
      </b-collapse>

      <!--   Post Type   -->
      <div
        v-if="!isGridView"
        class="title-header"
        :class="collapsable['type'] ? null : 'collapsed'"
        @click="(e) => handleCollapse('type', e)"
      >
        <div class="flex-1 pl-5 py-3.5">
          <span class="font-weight-500">Content Type</span>
        </div>
        <div>
          <div class="relative m-0 hover:text-black-900 flex">
            <span class="text-xs">
              <i
                class="
                  far
                  fa-chevron-up
                  text-gray-700
                  w-4
                  h-4
                  inline-flex
                  justify-center
                  items-center
                "
              ></i>
            </span>
            <label class="m-0 p-0 d-flex justify-between items-center">
              <input
                :checked="type.length === contentTypes.length"
                class="prevent-toggle check-input opacity-0 absolute h-8 w-8"
                type="checkbox"
                @change="(event) => handleSelectAll(event, 'type')"
              />
              <div
                class="
                  prevent-toggle
                  relative
                  cursor-pointer
                  bg-white
                  rounded-md
                  w-5
                  h-5
                  flex flex-shrink-0
                  justify-center
                  items-center
                  ml-2
                  focus-within:border-blue-500
                "
              >
                <svg
                  class="
                    fill-current
                    hidden
                    w-2.5
                    h-2.5
                    text-blue-500
                    pointer-events-none
                  "
                  version="1.1"
                  viewBox="0 0 17 12"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill="none" fill-rule="evenodd">
                    <g
                      transform="translate(-9 -11)"
                      fill="#0088cc"
                      fill-rule="nonzero"
                    >
                      <path
                        d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z"
                      />
                    </g>
                  </g>
                </svg>
              </div>
            </label>
          </div>
        </div>
      </div>
      <b-collapse
        v-if="!isGridView"
        id="post-type"
        v-model="collapsable['type']"
        visible
      >
        <div class="py-1.5">
          <div
            v-for="(item, index) in contentTypes"
            :key="`post_types_${index}`"
            class="py-2"
          >
            <SimpleCheckbox v-model="type" :name="item.value" class="group">
              <template v-slot:label>
                <span class="w-32">{{ item.name }}</span>
                <span
                  class="
                    ml-2
                    text-xs
                    font-normal
                    border
                    rounded-md
                    bg-gray-400
                    hover:bg-gray-500
                    px-1
                    hidden
                    group-hover:inline-flex
                    absolute
                    right-3
                  "
                  @click.stop.prevent="handleOnlyFilter('type', item.value)"
                  >only</span
                >
              </template>
              <template v-slot:count>
                {{ plansCount[item.countKey] }}
              </template>
            </SimpleCheckbox>
          </div>
        </div>
      </b-collapse>

      <!--   Labels   -->
      <div
        v-if="!isGridView"
        :class="collapsable['labels'] ? null : 'collapsed'"
        class="title-header"
        @click="(e) => handleCollapse('labels', e)"
      >
        <div class="flex-1 pl-5 py-3.5">
          <span class="font-weight-500">Labels</span>
        </div>
        <div>
          <div class="relative m-0 hover:text-black-900 flex">
            <span class="text-xs">
              <i
                class="
                  far
                  fa-chevron-up
                  text-gray-700
                  w-4
                  h-4
                  inline-flex
                  justify-center
                  items-center
                "
              ></i>
            </span>
            <label class="m-0 p-0 d-flex justify-between items-center">
              <input
                :checked="
                  orderedLabels.length === selectedTags.labels.length &&
                  orderedLabels.length !== 0
                "
                class="prevent-toggle check-input opacity-0 absolute h-8 w-8"
                type="checkbox"
                :disabled="orderedLabels.length === 0"
                @change="(event) => handleSelectAll(event, 'labels')"
              />

              <div
                :class="{
                  'grayscale filter cursor-not-allowed':
                    orderedLabels.length === 0,
                }"
                class="
                  prevent-toggle
                  relative
                  cursor-pointer
                  bg-white
                  rounded-md
                  w-5
                  h-5
                  flex flex-shrink-0
                  justify-center
                  items-center
                  ml-2
                  focus-within:border-blue-500
                "
              >
                <svg
                  class="
                    fill-current
                    hidden
                    w-2.5
                    h-2.5
                    text-blue-500
                    pointer-events-none
                  "
                  version="1.1"
                  viewBox="0 0 17 12"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill="none" fill-rule="evenodd">
                    <g
                      transform="translate(-9 -11)"
                      fill="#0088cc"
                      fill-rule="nonzero"
                    >
                      <path
                        d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z"
                      />
                    </g>
                  </g>
                </svg>
              </div>
            </label>
          </div>
        </div>
      </div>
      <b-collapse
        v-if="!isGridView"
        id="label-collapse"
        v-model="collapsable['labels']"
        visible
      >
        <div class="py-4">
          <CstLabelsDropdown
            :tags="
              orderedLabels.map((label) => ({
                id: label._id,
                text: label.name,
                checked: selectedTags.labels.includes(label._id),
              }))
            "
            placeholder="Search Labels"
            @change="(tags) => handleTagsSelection(tags, 'labels')"
          >
            <template v-slot:no-data> No Label Found! </template>
          </CstLabelsDropdown>
        </div>
      </b-collapse>

      <!--   Composer Campaigns   -->
      <div
        v-if="!isGridView"
        class="title-header"
        :class="collapsable['campaigns'] ? null : 'collapsed'"
        @click="(e) => handleCollapse('campaigns', e)"
      >
        <div class="flex-1 pl-5 py-3.5">
          <span class="font-weight-500">Campaigns</span>
        </div>
        <div>
          <div class="relative m-0 hover:text-black-900 flex">
            <span class="text-xs">
              <i
                class="
                  far
                  fa-chevron-up
                  text-gray-700
                  w-4
                  h-4
                  inline-flex
                  justify-center
                  items-center
                "
              ></i>
            </span>
            <label class="m-0 p-0 d-flex justify-between items-center">
              <input
                :checked="
                  plansCount.composer_folders.length ===
                    selectedTags.campaigns.length &&
                  plansCount.composer_folders.length !== 0
                "
                class="prevent-toggle check-input opacity-0 absolute h-8 w-8"
                type="checkbox"
                :disabled="plansCount.composer_folders.length === 0"
                @change="(event) => handleSelectAll(event, 'campaigns')"
              />

              <div
                :class="{
                  'grayscale filter cursor-not-allowed':
                    plansCount.composer_folders.length === 0,
                }"
                class="
                  prevent-toggle
                  relative
                  cursor-pointer
                  bg-white
                  rounded-md
                  w-5
                  h-5
                  flex flex-shrink-0
                  justify-center
                  items-center
                  ml-2
                  focus-within:border-blue-500
                "
              >
                <svg
                  class="
                    fill-current
                    hidden
                    w-2.5
                    h-2.5
                    text-blue-500
                    pointer-events-none
                  "
                  version="1.1"
                  viewBox="0 0 17 12"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill="none" fill-rule="evenodd">
                    <g
                      transform="translate(-9 -11)"
                      fill="#0088cc"
                      fill-rule="nonzero"
                    >
                      <path
                        d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z"
                      />
                    </g>
                  </g>
                </svg>
              </div>
            </label>
          </div>
        </div>
      </div>
      <b-collapse
        v-if="!isGridView"
        id="campaigns-collapse"
        v-model="collapsable['campaigns']"
        visible
      >
        <div class="py-4">
          <CstTagsDropdown
            :tags="
              plansCount.composer_folders
                .map((campaign) => ({
                  id: campaign._id,
                  text:
                    campaign._id === 'default_campaign'
                      ? getDefaultCampaignName + ` (${campaign.counts})`
                      : campaign.name + ` (${campaign.counts})`,
                  color: campaign.color,
                  checked: selectedTags.campaigns.includes(campaign._id),
                }))
                ?.sort((a, b) => a.text.localeCompare(b.text))
            "
            placeholder="Search Campaigns"
            @change="(tags) => handleTagsSelection(tags, 'campaigns')"
          >
            <template v-slot:no-data> No Campaign Found! </template>
          </CstTagsDropdown>
        </div>
      </b-collapse>

      <!--   Content Categories   -->
      <div
        v-if="!isGridView"
        class="title-header"
        :class="collapsable['categories'] ? null : 'collapsed'"
        @click="(e) => handleCollapse('categories', e)"
      >
        <div class="flex-1 pl-5 py-3.5">
          <span class="font-weight-500">Content Categories</span>
        </div>
        <div>
          <div class="relative m-0 hover:text-black-900 flex">
            <span class="text-xs">
              <i
                class="
                  far
                  fa-chevron-up
                  text-gray-700
                  w-4
                  h-4
                  inline-flex
                  justify-center
                  items-center
                "
              ></i>
            </span>
            <label class="m-0 p-0 d-flex justify-between items-center">
              <input
                :checked="
                  getContentCategoryList.length ===
                    selectedTags.categories.length &&
                  getContentCategoryList.length !== 0
                "
                class="prevent-toggle check-input opacity-0 absolute h-8 w-8"
                type="checkbox"
                :disabled="getContentCategoryList.length === 0"
                @change="(event) => handleSelectAll(event, 'categories')"
              />

              <div
                :class="{
                  'grayscale filter cursor-not-allowed':
                    getContentCategoryList.length === 0,
                }"
                class="
                  prevent-toggle
                  relative
                  cursor-pointer
                  bg-white
                  rounded-md
                  w-5
                  h-5
                  flex flex-shrink-0
                  justify-center
                  items-center
                  ml-2
                  focus-within:border-blue-500
                "
              >
                <svg
                  class="
                    fill-current
                    hidden
                    w-2.5
                    h-2.5
                    text-blue-500
                    pointer-events-none
                  "
                  version="1.1"
                  viewBox="0 0 17 12"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill="none" fill-rule="evenodd">
                    <g
                      transform="translate(-9 -11)"
                      fill="#0088cc"
                      fill-rule="nonzero"
                    >
                      <path
                        d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z"
                      />
                    </g>
                  </g>
                </svg>
              </div>
            </label>
          </div>
        </div>
      </div>
      <b-collapse
        v-if="!isGridView"
        id="categories-collapse"
        v-model="collapsable['categories']"
        visible
      >
        <div class="py-4">
          <CstTagsDropdown
            :tags="
              getContentCategoryList.map((category) => ({
                id: category._id,
                text: category.name,
                checked: selectedTags.categories.includes(category._id),
              }))
            "
            placeholder="Search Content Categories"
            @change="(tags) => handleTagsSelection(tags, 'categories')"
          >
            <template v-slot:no-data> No Content Category Found! </template>
          </CstTagsDropdown>
        </div>
      </b-collapse>
    </div>

    <hr v-if="!isGridView" class="m-0" />
    <div v-if="!isGridView" class="p-5 ml-auto">
      <CstButton
        variant="secondary"
        text="Reset Filters"
        @click="handleClear"
      />
      <CstButton
        text="Apply"
        class="ml-1.5"
        @click="handleApply(null)"
      />
    </div>
  </div>
</template>

<script>
import CstButton from '@ui/Button/CstButton'
import CstTagsDropdown from '@ui/Dropdown/CstTagsDropdown'
import SimpleCheckbox from '@src/modules/planner_v2/components/SimpleCheckbox'
import { getIconClass } from '@src/modules/planner_v2/utils'
import { mapGetters } from 'vuex'

import Helpers from '@src/modules/planner_v2/mixins/Helpers'
import { planner } from '@src/modules/planner/store/mutation-types'
import FilterDropdown from '@src/modules/planner_v2/components/FilterDropdown'
import { isEmpty, isEqual } from 'lodash'
import { labelsMixin } from '@common/mixins/publish/labelsMixin'
import CstLabelsDropdown from '@ui/Dropdown/CstLabelsDropdown'
import { stringMixin } from '@common/mixins/utils/stringMixin'
import { EventBus } from '@common/lib/event-bus'
import useSocialGridView from '@src/modules/planner_v2/composables/useSocialGridView'
import ComposerHelper from '@src/modules/composer_v2/mixins/ComposerHelper'
import EmptyState from '@/src/modules/planner_v2/components/SocialMediaViewer/EmptyState'

const { selectedAccount, noStatusSelected } = useSocialGridView()

export default {
  name: 'FilterSidebar',
  components: {
    CstLabelsDropdown,
    CstButton,
    CstTagsDropdown,
    SimpleCheckbox,
    FilterDropdown,
    EmptyState,
  },
  mixins: [Helpers, labelsMixin, stringMixin, ComposerHelper],
  props: {
    plansCount: {
      type: Object,
      default: () => ({}),
    },
    contentTypes: {
      type: Array,
      default: () => [],
    },
  },
  emits: [
    'handle-plans-count-reset',
    'fetch-plans',
    'handle-query-change',
    'social-account',
    'close',
  ],
  data() {
    return {
      filterTypes: [
        'statuses',
        'social_accounts',
        'assigned_members',
        'created_members',
        'labels',
        'campaigns',
        'categories',
        'type',
      ],
      type: [],
      sort: '',
      statuses: [],
      selectedAssignedMembers: [],
      selectedCreatedMembers: [],
      selectedSocialAccounts: [],
      selectedTags: {
        labels: [],
        campaigns: [],
        categories: [],
      },
      noAccount: false,
      currentFilter: {},
      collapsable: {
        status: true,
        accounts: true,
        members: true,
        created_by: true,
        labels: true,
        campaigns: true,
        categories: true,
        type: true,
      },
      filterCheckboxes: {
        status: false,
        accounts: false,
        members: false,
        created_by: false,
        labels: false,
        campaigns: false,
        categories: false,
        type: false,
      },
      selectAll: false,
      isOpen: true,
      calendarDate: null,
      savedFilters: [],
    }
  },
  computed: {
    ...mapGetters([
      'getContentCategoryList',
      'getTotalMyPublications',
      'getWorkspaces',
      'getPlatformsList',
    ]),
    selectedSort() {
      if (!this.sort) {
        return 'Newest'
      }
      return this.sort.charAt(0).toUpperCase() + this.sort.slice(1)
    },
    selectedTypes() {
      switch (this.type) {
        case 'repeat':
          return 'Repeat posts'
        case 'article_automation':
          return 'Article Automations Posts '
        case 'video_automation':
          return 'Video Automations Posts'
        case 'evergreen_automation':
          return 'Evergreen Automations Posts'
        case 'rss_automation':
          return 'RSS Automations Posts'
        case 'csv_automation':
          return 'CSV Automations Posts'
        case 'queued':
          return 'Queued Posts'
        default:
          return 'All Posts'
      }
    },

    /**
     * returns true if the selected filter is modified or if no filter is selected, returns false otherwise
     * @returns {boolean}
     */
    currentFilterModified() {
      let modified = true
      if (
        isEmpty(this.currentFilter) &&
        isEmpty(this.selectedTags.campaigns) &&
        isEmpty(this.selectedTags.categories) &&
        isEmpty(this.selectedCreatedMembers) &&
        isEmpty(this.selectedAssignedMembers) &&
        isEmpty(this.statuses) &&
        isEmpty(this.type)
      )
        return !modified
      if (
        isEqual(this.selectedTags.campaigns, this.currentFilter.campaigns) &&
        isEqual(
          this.selectedTags.categories,
          this.currentFilter.content_category
        ) &&
        isEqual(this.selectedCreatedMembers, this.currentFilter.created_by) &&
        isEqual(this.selectedAssignedMembers, this.currentFilter.members) &&
        isEqual(this.statuses, this.currentFilter.statuses) &&
        isEqual(this.type, this.currentFilter.type) &&
        this.currentFilter?.platformSelection &&
        isEqual(
          this.selectedSocialAccounts,
          this.currentFilter.platformSelection
        )
      ) {
        modified = false
      }
      return modified
    },
  },
  watch: {
    '$route.name'(newVal, oldVal){
      if(newVal && oldVal && (oldVal.includes('tiktok') || oldVal.includes('instagram'))){
        this.selectedSocialAccounts = []
      }
    },
    selectedSocialAccounts(newVal, oldVal) {
      if (this.isGridView) {
        if (newVal.length === 0 && oldVal.length === 1) {
          this.selectedSocialAccounts = [oldVal[0]]
        } else {
          if (newVal.length > 1) {
            this.selectedSocialAccounts = [
              this.selectedSocialAccounts[
                this.selectedSocialAccounts.length - 1
              ],
            ]
          } else if (newVal.length === 1) {
            if (this.$route.name?.includes('tiktok')) {
              const tiktokAccount =
                this.$store.getters.getTiktokAccounts.items.find(
                  (account) => account.platform_identifier === newVal[0]
                )
              if (tiktokAccount?.platform_identifier) {
                this.setTiktokAccount(tiktokAccount)
              }
            } else {
              const instagramAccount =
                this.$store.getters.getInstagramAccounts.items.find(
                  (account) => account.instagram_id === newVal[0]
                )
              if (instagramAccount?.instagram_id) {
                this.setInstagramAccount(instagramAccount)
              }
            }
          } else {
            selectedAccount.value = null
          }
        }
      }
    },
    statuses(newVal) {
      noStatusSelected.value = newVal.length === 0

      if (this.isGridView && selectedAccount.value !== null) {
        this.handleApply(null, false)
      }
    },
  },
  async created() {
    this.savedFilters = await this.fetchPlannerFilters()
    if (!this.isGridView) {
      this.handleDefaultFilter()
    }
  },
  async mounted() {
    if (!this.isGridView) {
      this.selectAllFilters()
    }

    EventBus.$on('workspace-changed', async () => {
      this.savedFilters = await this.fetchPlannerFilters()
      await this.handleClear(false)
      if (!this.isGridView) {
        this.handleDefaultFilter()
      } else {
        selectedAccount.value = null
        await this.onGridViewMount()
      }
      // EventBus.$emit('set-sort-data')
      // this.selectAllFilters()
    })

    // apply filter from other components and mixins
    EventBus.$on('planner-apply-filter', async ({ filter, type }) => {
      switch (type) {
        case 'campaign':
          this.selectedTags.campaigns = filter
      }
    })

    EventBus.$on('grid-view-mount', async () => {
      selectedAccount.value = null
      const persistedStatuses = this.$route.query.statuses
      const persistedId = this.$route.query.account_id
      await this.handleClear(false)

      // setting statuses and account on page refresh
      this.$route.query.statuses = persistedStatuses
      if (this.$route.name.includes('tiktok')) {
        const account =
          persistedId &&
          this.$store.getters.getTiktokAccounts?.items?.find(
            (i) => i.platform_identifier === persistedId
          )
        await this.onGridViewMount(account ? account.platform_identifier : null)
      } else {
        const account =
          persistedId &&
          this.$store.getters.getInstagramAccounts?.items?.find(
            (i) => i.instagram_id === persistedId
          )
        await this.onGridViewMount(account ? account.instagram_id : null)
      }
    })

    EventBus.$on('grid-view-unmount', async () => {
      // selectedAccount.value = null
      // this.selectedSocialAccounts = []
      this.statuses = []
      await this.handleClear(false, false)
      await this.handleDefaultFilter()
    })
  },
  beforeUnmount() {
    EventBus.$off('workspace-changed')
    EventBus.$off('grid-view-mount')
    EventBus.$off('grid-view-unmount')
  },
  methods: {
    async onGridViewMount(accountId = null) {
      if (this.$route.query?.statuses) {
        this.statuses = this.$route.query?.statuses.split(',')
      }

      if(accountId) {
       this.selectedSocialAccounts = [accountId]
      } else {
        this.$emit('handle-plans-count-reset')
        if (this.$route.name.includes('tiktok')) {
          await this.setTiktokAccount()
        } else {
          await this.setInstagramAccount()
        }
      }
    },
    async setInstagramAccount(account) {
      if (account) {
        if (selectedAccount.value?.instagram_id !== account?.instagram_id) {
          selectedAccount.value = account
          await this.handleApply(null, false)
        }
      } else if (selectedAccount.value === null) {
        const instagramAccounts = this.$store.getters.getInstagramAccounts.items

        if (instagramAccounts.length > 0) {
          this.selectedSocialAccounts = [instagramAccounts[0].instagram_id]
        }
      }
    },
    async setTiktokAccount(account) {
      if (account) {
        if (
          selectedAccount.value?.platform_identifier !==
          account?.platform_identifier
        ) {
          selectedAccount.value = account
          await this.handleApply(null, false)
        }
      } else if (selectedAccount.value === null) {
        const tiktokAccounts = this.$store.getters.getTiktokAccounts.items

        if (tiktokAccounts.length > 0) {
          this.selectedSocialAccounts = [tiktokAccounts[0].platform_identifier]
        }
      }
    },
    async setDefaultFilterStatus(filterId, status, index) {
      const payload = {
        filter_id: filterId,
        workspace_id: this.getWorkspaces.activeWorkspace._id,
        status,
      }
      const response = await this.setDefaultPlannerFilter(payload)

      if (response) this.savedFilters = response
    },
    async removeSavedFilter(filterId, index) {
      const payload = {
        filter_id: filterId,
        workspace_id: this.getWorkspaces.activeWorkspace._id,
      }
      const response = await this.removePlannerFilter(payload)
      if (response) {
        this.savedFilters?.splice(index, 1)
      }
    },
    /**
     * checks for default filter and applies it in planner.
     */
    handleDefaultFilter() {
      const defaultFilter = this.savedFilters?.find(
        (item) => item?.default === true
      )
      if (
        defaultFilter &&
        !('plan_ids' in this.$route.query) &&
        !('statuses' in this.$route.query) &&
        !('automations' in this.$route.query)
      ) {
        this.implementFilter(defaultFilter)
      } else {
        EventBus.$emit('set-sort-data')
      }
    },

    /**
     * method to toggle collapse
     * @param key string
     * @param event Event
     */
    handleCollapse(key, event) {
      if (!event.target.classList.contains('prevent-toggle')) {
        this.collapsable[key] = !this.collapsable[key]
      }
    },
    /**
     * fetches account images if it exists. returns a default profile image in case of no-image/error.
     * @param account object
     * @param type string
     */
    channelImage(account, type) {
      const image =
        'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
      const gmbImage =
        'https://storage.googleapis.com/lumotive-web-storage/default/google-business.png'
      if (!account) return image

      if (account && account.platform_logo) return account.platform_logo

      switch (type) {
        case 'facebook':
          return account.image
            ? account.image
            : account.picture && account.picture.url
            ? account.picture.url
            : image
        case 'twitter':
        case 'linkedin':
        case 'pinterest':
          return account.image && account.image.large && account.image.large.url
            ? account.image.large.url
            : account.image
            ? account.image
            : image
        case 'instagram':
          return account.image
            ? account.image
            : account.profile_picture
            ? account.profile_picture
            : image
        case 'threads':
        case 'bluesky':
          return account.image
              ? account.image
              : account.profile_picture
              ? account.profile_picture
              : image
        case 'medium':
          return account.imageUrl ? account.imageUrl : image
        case 'gmb':
          return account.location_name ? gmbImage : image
      }
    },
    setCheckAllOptions() {
      this.selectAllFilters()
      this.handleApply(null)
    },
    async clearSidebarFilters(ClearActiveFilter = false) {

      const query = Object.assign({}, this.$route.query)
      delete query.type
      delete query.labels
      delete query.campaigns
      delete query.content_category
      delete query.created_by
      delete query.members
      delete query.date
      delete query.statuses

      if (ClearActiveFilter) {
        delete query.filter_id
        this.$store.commit(planner.SET_PLANNER_ACTIVE_FILTER, null)
      }
      await this.$router.push({ query })
      this.$emit('handle-query-change', query)
      this.$store.commit(planner.SET_CHECK_ALL_STATUSES, true)
      this.$store.commit(planner.SET_CHECK_ALL_CREATED_MEMBERS, true)
      this.$store.commit(planner.SET_CHECK_ALL_ASSIGNED_MEMBERS, true)
      this.$store.commit(planner.SET_CHECK_ALL_LABELS, true)
      this.$store.commit(planner.SET_CHECK_ALL_CAMPAIGNS, true)
      this.$store.commit(planner.SET_CHECK_ALL_CATEGORIES, true)
    },
    selectAllFilters() {
      const query = Object.assign({}, this.$route.query)

      if (!query.type) {
        this.type = []
      } else {
        this.type = query.type.split(',')
      }

      if (!query.labels) {
        this.selectedTags.labels = []
      } else {
        this.selectedTags.labels = query.labels.split(',')
      }

      if (!query.campaigns) {
        this.selectedTags.campaigns = []
      } else {
        this.selectedTags.campaigns = query.campaigns.split(',')
      }

      if (!query.content_category) {
        this.selectedTags.categories = []
      } else {
        this.selectedTags.categories = query.content_category.split(',')
      }


      if (!query.created_by) {
        this.selectedCreatedMembers = []
      } else {
        this.selectedCreatedMembers = query.created_by.split(',')
      }

      if (!query.members) {
        this.selectedAssignedMembers = []
      } else {
        this.selectedAssignedMembers = query.members.split(',')
      }

      if (!query.statuses) {
        this.statuses = []
      } else {
        this.statuses = query.statuses.split(',')
      }

      if (!query.no_account) {
        this.noAccount = false
      } else {
        this.noAccount = query.no_account === 'true'
      }

      this.selectedSocialAccounts = []

      // this.handleApply(null)
    },

    changeSort(type) {
      this.sort = type
    },

    changeType(type) {
      this.type = type
    },

    handleSelectAll(event, type) {
      if (type === 'statuses') {
        this.filterCheckboxes.status = event.target.checked
        if (event.target.checked) {
          this.statuses = [
            'scheduled',
            'published',
            'partial',
            'failed',
            'rejected',
            'under_review',
            'missed_review',
            'draft',
            'in_progress',
          ]
        } else {
          this.statuses = []
        }
      } else if (type === 'social_accounts') {
        this.filterCheckboxes.accounts = event.target.checked
        if (event.target.checked) {
          this.selectedSocialAccounts = this.getPlatformsList.map((item) => {
            if (item.account_type && item.account_type === 'blog') {
              return item.platform_identifier
            }
            return item[item.channel_identifier]
          })

          this.noAccount = true
        } else {
          this.selectedSocialAccounts = []
          this.noAccount = false
        }
      } else if (type === 'assigned_members') {
        this.filterCheckboxes.members = event.target.checked
        if (event.target.checked) {
          this.selectedAssignedMembers = this.membersList.map(
            (item) => item.user._id
          )
        } else {
          this.selectedAssignedMembers = []
        }
      } else if (type === 'created_members') {
        this.filterCheckboxes.created_by = event.target.checked
        if (event.target.checked) {
          this.selectedCreatedMembers = this.membersList.map(
            (item) => item.user._id
          )
        } else {
          this.selectedCreatedMembers = []
        }
      } else if (type === 'labels') {
        this.filterCheckboxes.labels = event.target.checked
        if (event.target.checked) {
          this.selectedTags.labels = this.orderedLabels.map(
            (label) => label._id
          )
        } else {
          this.selectedTags.labels = []
        }
      } else if (type === 'campaigns') {
        this.filterCheckboxes.campaigns = event.target.checked
        if (event.target.checked) {
          this.selectedTags.campaigns = this.plansCount.composer_folders.map(
            (campaign) => campaign._id
          )
          this.selectedTags.campaigns.push('default_campaign')
        } else {
          this.selectedTags.campaigns = []
        }
      } else if (type === 'categories') {
        this.filterCheckboxes.categories = event.target.checked
        if (event.target.checked) {
          this.selectedTags.categories = this.getContentCategoryList.map(
            (category) => category._id
          )
        } else {
          this.selectedTags.categories = []
        }
      } else if (type === 'type') {
        this.filterCheckboxes.type = event.target.checked
        if (event.target.checked) {
          this.type = this.contentTypes.map((item) => item.value)
        } else {
          this.type = []
        }
      }
    },
    handleTagsSelection(tags, type = 'labels') {
      this.selectedTags[type] = tags.map((tag) => tag.id)
    },
    async handleApply(routeQuery = null, refresh = false) {

      // first empty the calendar events (to prevent unnecessary re-rendering of the calendar events)
      // then re-fetch the plans
      EventBus.$emit('empty-calendar-events')

      const currentRoute = this.$route
      const query = routeQuery || Object.assign({}, currentRoute.query)
      // Object.assign({}, { ...query, ...routeQuery })
      if (this.type.length) {
        query.type = this.type.join()
      } else {
        delete query.type
      }
      /* if (this.type.length) {
        query.type = this.type.join();
      } else if (this.type === '') {
        query.type = this.type.join()
      } */
      if (this.noAccount) {
        query.no_account = this.noAccount
      } else {
        delete query.no_account
      }

      if (this.sort) {
        query.order_by = this.sort
      } else {
        delete query.sort
      }

      if (this.statuses.length) {
        query.statuses = this.statuses.join()
      } else {
        delete query.statuses
      }

      if (this.selectedSocialAccounts.length) {
        this.$emit(
          'social-account',
          this.selectedSocialAccounts,
          this.noAccount
        )
      } else {
        this.$emit('social-account', [], this.noAccount)
      }

      if (this.selectedTags.labels.length) {
        query.labels = this.selectedTags.labels.join()
      } else {
        delete query.labels
      }

      if (this.selectedTags.campaigns.length) {
        query.campaigns = this.selectedTags.campaigns.join()
      } else {
        delete query.campaigns
      }

      if (this.selectedTags.categories.length) {
        query.content_category = this.selectedTags.categories.join()
      } else {
        delete query.content_category
      }

      if (this.selectedAssignedMembers.length) {
        query.members = this.selectedAssignedMembers.join()
      } else {
        delete query.members
      }

      if (this.selectedCreatedMembers.length) {
        query.created_by = this.selectedCreatedMembers.join()
      } else {
        delete query.created_by
      }

      if (this.isGridView) {
        query.account_id = this.$route.name.includes('tiktok')
          ? selectedAccount.value?.platform_identifier
          : selectedAccount.value?.instagram_id
      } else {
        delete query.account_id
      }

      await this.$nextTick()
      if (
        refresh ||
        (isEqual(query, currentRoute.query) &&
          this.selectedSocialAccounts.length)
      ) {
        // this.$emit('fetch-plans')
      }

      await this.$router.replace({ query })
      this.$emit('handle-query-change', query)
    },
    async handleClear(fetchPlans = true, resetSocialAccounts = true) {
      const date = this.$route.query.date
      const query = {}
      if (this.$route.name === 'planner_calendar_v2') {
        // first empty the calendar events (to prevent unnecessary re-rendering of the calendar events)
        // then re-fetch the plans
        EventBus.$emit('empty-calendar-events')
        query.date = date
      }

      this.currentFilter = {}
      this.type = []
      this.statuses = []
      this.selectedAssignedMembers = []
      this.selectedCreatedMembers = []
      this.noAccount = false
      if(resetSocialAccounts){
        this.selectedSocialAccounts = []
      }
      this.$emit('social-account', this.selectedSocialAccounts, this.noAccount)
      this.selectedTags = {
        labels: [],
        campaigns: [],
        categories: [],
      }

      for (const key in this.filterCheckboxes) {
        this.filterCheckboxes[key] = false
      }
      EventBus.$emit('reset-date-filter')

      if (this.$route.query.plan_ids) {
        const newQuery = { ...this.$route.query }
        delete newQuery.plan_ids
        await this.$router.push({ query: newQuery })
      }

      if (!this.isGridView) {
        this.handleDefaultFilter()
      }
    },
    getIconClass(platform) {
      return getIconClass(platform)
    },

    /**
     * Emitted from FilterDropdown.vue with filterName as parameter and creates payload before createPlanner filter which sends a create/update filter request.
     * @param filterName
     * @param saveOption
     * @returns {Promise<void>}
     */
    async saveFilter(filterName, saveOption) {

      const payload = {
        campaigns: this.selectedTags.campaigns,
        content_category: this.selectedTags.categories,
        created_by: this.selectedCreatedMembers,
        default: false,
        labels: this.selectedTags.labels,
        members: this.selectedAssignedMembers,
        platformSelection: this.selectedSocialAccounts,
        name: filterName,
        statuses: this.statuses,
        type: this.type,
        workspace_id: this.getActiveWorkspace._id,
      }

      if (saveOption.optionType === 'existing') {
        payload.id = saveOption.id
        payload.name = Array.isArray(saveOption.name)
          ? saveOption.name.join(' ')
          : saveOption.name
      }

      const filter = await this.createPlannerFilter(payload)
      this.savedFilters = await this.fetchPlannerFilters()
      this.$refs['filter-dropdown'].filterName = ''
      this.implementFilter(filter)
    },

    /**
     * Emitted from FilterDropdown.vue with filter object and implements the filters as whole
     * @param filter
     */
    implementFilter(filter) {
      this.currentFilter = filter

      this.selectedTags.campaigns = filter.campaigns
      this.selectedTags.categories = filter.content_category
      this.selectedCreatedMembers = filter.created_by
      this.selectedAssignedMembers = filter.members
      this.statuses = filter.statuses
      Array.isArray(filter.type)
        ? (this.type = filter.type)
        : this.type.push(filter.type) // Handling the edge cases of old planner filters type(String)
      if (filter?.platformSelection)
        this.selectedSocialAccounts = filter.platformSelection

      EventBus.$emit('set-sort-data')
    },

    /**
     * Handles the only filter in the filter sidebar. sets that specific filter true and disables all other filter.
     * @param filterType
     * @param filterName
     */
    handleOnlyFilter(filterType, filterName) {
      if (filterType === 'noAccount') {
        this.noAccount = true
        this.selectedSocialAccounts = []
      } else {
        this[filterType] = []
        this[filterType].push(filterName)
        if (filterType === 'selectedSocialAccounts') {
          this.noAccount = false
        }
      }
      // this.handleApply()
    },

    /**
     * onClick event for toggle/selecting all post types.
     * @param event
     */
    onSelectAll(event) {
      this.filterTypes.forEach((item) => {
        this.handleSelectAll(event, item)
      })
    },

    /**
     * onClick action for toggling all collapsible post types.
     */
    toggleAllCollapsible() {
      this.isOpen = !this.isOpen
      for (const key in this.collapsable) {
        this.collapsable[key] = this.isOpen
      }
    },

    /**
     * Runs a loop on filterCheckboxes and returns true if all filters are checked, Returns false instead.
     * @returns {boolean}
     */
    allFiltersChecked() {
      let checked = true
      for (const key in this.filterCheckboxes) {
        if (!this.filterCheckboxes[key]) {
          checked = this.filterCheckboxes[key]
        }
      }

      return checked
    },

    /**
     * Used for v-tooltip, if the length of the string is greater than the length provided in argument,
     * then it will return the full name to be shown inside the tooltip.
     * Otherwise, it will return 'null' which will not show the toopltip
     * @param str
     * @param length
     * @returns {*|null}
     */
    tooltipContent(str, length) {
      return str?.length > length ? str : null
    },

    getSocialImage(platform) {
      switch (platform) {
        case 'facebook':
          return 'facebook-rounded.svg'
        case 'twitter':
          return 'twitter-x-rounded.svg'
        case 'linkedin':
          return 'linkedin-rounded.svg'
        case 'instagram':
          return 'instagram-rounded.svg'
        case 'pinterest':
          return 'pinterest-rounded.svg'
        case 'youtube':
          return 'youtube-rounded.png'
        case 'gmb':
          return 'gmb-rounded.svg'
        case 'tumblr':
          return 'tumblr-rounded.svg'
        case 'tiktok':
          return 'tiktok-rounded.svg'
        case 'threads':
          return 'threads-rounded.svg'
        case 'bluesky':
          return 'bluesky-rounded.svg'
      }
    },
    getBlogImage(platform) {
      switch (platform) {
        case 'wordpress':
          return 'wordpress.svg'
        case 'medium':
          return 'medium.svg'
        case 'webflow':
          return 'webflow.svg'
        case 'tumblr':
          return 'tumblr-rounded.svg'
        case 'shopify':
          return 'shopify.svg'
      }
    },
  },
}
</script>

<style scoped lang="scss">
.text-ellipsis {
  display: block;
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

input.check-input + div {
  //border-width: 1px;
  //@apply border-solid;
  //@apply border-gray-400;

  background-color: transparent;
  border: 1.5px solid #99a3a982;
  border-radius: 0.3rem;
  width: 16px;
  height: 16px;
}

input.check-input:checked + div {
  @apply border-blue-300;
}
input.check-input:checked + div svg {
  @apply block;
}

.filter-bar {
  border-right: 1px solid #dee2e6;
  max-width: 18rem;
  width: 18rem;

  i {
    transition: all 0.2s ease-in-out;
  }
  .collapsed {
    //background: #ffffff;

    .fa-chevron-up {
      transform: rotate(180deg);
    }
  }

  .title-header {
    @apply cursor-pointer flex justify-between items-center -mx-5  pr-5 bg-cs-metalgray hover:bg-cs-foggray;
    //background: #8cb4db14;
    transition: all 0.2s ease-in-out;
  }
}

.cst-account-checkbox__platform {
  font-size: 0.7rem;
  text-align: center;
}

.grayscale.filter.cursor-not-allowed {
  background: #e3e8eb !important;
}

.img-border {
  border: 0.25px solid #e8e8e8;
}
</style>
