import {
  LABEL_COLOR_MISSING,
  LABEL_NAME_MISSING,
  UNKNOWN_ERROR
} from '@common/constants/messages'
import proxy from '@common/lib/http-common'
import {
  fetchLabelsUrl,
  removeLabelUrl,
  saveLabelUrl
} from '../../../planner/config/api-utils'
import { publish } from './mutation-types'

const defaultPublishLabels = {
  fetchLabels: false,
  items: [],
  current_label: [],
  loaders: {
    visible: false,
    save: false,
    retrieve: false
  }
}

export default {
  state: {
    labels: {
      fetchLabels: false,
      items: [],
      current_label: [],
      loaders: {
        visible: false,
        save: false,
        retrieve: false
      }
    }
  },
  actions: {
    /**
     * Get the labels based on a workspace id
     * @param commit
     * @param getters
     * @param state
     */
    fetchLabels ({ commit, getters, state }) {
      if (getters.getWorkspaces.activeWorkspace._id) {
        commit('SET_RETRIEVE_LABEL', true)
        proxy
          .post(fetchLabelsUrl, {
            workspace_id: getters.getWorkspaces.activeWorkspace._id
          })
          .then((resp) => {
            if (resp.data.status) {
              commit('SET_LABELS', resp.data.labels)
              commit('SET_RETRIEVE_LABEL', true)
            } else {
              commit('SET_LABELS', [])
              commit('SET_RETRIEVE_LABEL', false)
            }
            console.log(resp)
          })
          .catch()
      }
    },

    /**
     * Get the labels for guest users where we dont have a workspaceId set
     * @param commit
     * @param getters
     * @param state
    */
    // TODO: this is a duplicate but needed for guests. what can we do?
    fetchLabelsForGuest ({ commit }, workspaceId) {
      if (!workspaceId) {
        console.error('No workspaceId provided');
        return;
      }

      commit('SET_RETRIEVE_LABEL', true)
      proxy
        .post(fetchLabelsUrl, {
          workspace_id: workspaceId
        })
        .then((resp) => {
          if (resp.data.status) {
            commit('SET_LABELS', resp.data.labels)
            commit('SET_RETRIEVE_LABEL', true)
          } else {
            commit('SET_LABELS', [])
            commit('SET_RETRIEVE_LABEL', false)
          }
          console.log(resp)
        })
        .catch()
    },

    /**
     * Save a new label to the account
     * @param commit
     * @param state
     * @param dispatch
     * @param getters
     */
    saveLabel ({ commit, state, dispatch, getters }) {
      if (!getters.getNewLabel.name) {
        dispatch('toastNotification', {
          message: LABEL_NAME_MISSING,
          type: 'error'
        })
        return
      }

      if (!getters.getNewLabel.name.replace(/\s/g, '').length) {
        dispatch('toastNotification', {
          message: LABEL_NAME_MISSING,
          type: 'error'
        })
        return
      }

      if (getters.getNewLabel.name == '') {
        dispatch('toastNotification', {
          message: LABEL_NAME_MISSING,
          type: 'error'
        })
        return
      }
      if (!getters.getNewLabel.color) {
        dispatch('toastNotification', {
          message: LABEL_COLOR_MISSING,
          type: 'error'
        })
        return
      }

      // prepare payload

      const payload = {
        workspace_id: getters.getWorkspaces.activeWorkspace._id,
        name: getters.getNewLabel.name,
        color: getters.getNewLabel.color
      }

      // if it is existing item

      if (getters.getNewLabel._id) {
        payload.label = getters.getNewLabel._id
      }
      commit('SET_SAVE_LABEL_LOADER', true)
      proxy
          .post(saveLabelUrl, payload)
          .then((resp) => {
            if (resp.data.status) {
              dispatch('trackEvent', {event: 'labels_created'})
              commit('SET_SAVE_LABEL_LOADER', false)
              const element = document.getElementById('labelcancel')
              if (element) {
                element.click()
              }
              // if the label already exists, update by index

              if (payload.label) {
                commit('UPDATE_LABEL_BY_INDEX', resp.data.label)
              } else {
                commit('ADD_LABEL', resp.data.label)
                commit(publish.ADD_PUBLISH_LABELS_ITEM, resp.data.label._id)
              }
              commit('SET_NEW_LABEL', null)
            }
          })
          .catch((error) => {
            console.error("Error in saving label", error)
          })
    },

    /**
     * Delete a label.
     * @param commit
     * @param getters
     * @param dipsatch
     * @param label
     */
    deleteLabel ({ commit, getters, dipsatch }, label) {
      proxy
        .post(removeLabelUrl, {
          workspace_id: getters.getWorkspaces.activeWorkspace._id,
          label: label._id
        })
        .then((resp) => {
          if (resp.data.status) {
            getters.getLabels.splice(getters.getLabels.indexOf(label), 1)
          }
        })
    },
    /** Reset labels when someone changes the workspace
     *
     * @param commit
     */
    resetLabels ({ commit }) {
      commit('resetLabels')
    }
  },
  mutations: {
    SET_RETRIEVE_LABEL (state, status) {
      state.labels.loaders.retrieve = status
    },

    SET_LABELS (state, labels) {
      state.labels.items = labels
    },

    ADD_LABEL (state, label) {
      console.debug("ADD_LABEL", label)
      state.labels.items.unshift(label)
      console.debug("ADD_LABEL", state.labels.items)
    },

    UPDATE_LABEL_BY_INDEX (state, label) {
      if (state.labels.items) {
        state.labels.items.splice(
          state.labels.items.findIndex((item) => label._id === item._id),
          1,
          label
        )
      }
    },

    SET_NEW_LABEL (state, label) {
      if (label) {
        state.labels.current_label = label
      } else state.labels.current_label = {}
    },

    RESET_LABELS_STATE (state) {
      state.labels = JSON.parse(JSON.stringify(defaultPublishLabels))
    },

    resetLabels (state) {
      state.labels.fetchLabels = false
      state.labels.items = []
    },

    // loaders

    SET_SAVE_LABEL_LOADER (state, value) {
      state.labels.loaders.save = value
    }
  },
  getters: {
    getLabels: (state) => {
      return state.labels.items
    },
    getNewLabel: (state) => {
      return state.labels.current_label
    },
    getSaveLabelLoader: (state) => {
      return state.labels.loaders.save
    }
  }
}
