<!-- eslint-disable vue/attributes-order -->
<template>
  <div>
    <header class="h-[60px] bg-[#005fd0] flex items-center justify-center px-4 py-2">
      <a
        :href="sanitizeUrl(isWhiteLabelDomain ? location : 'https://app.contentstudio.io/')"
        target="_blank" rel="noopener"
        class="flex items-center justify-center gap-2"
      >
        <img
          :src="
            isWhiteLabelDomain
              ? appData.brandLogo
              : 'https://contentstudio.io/images/Logo%20White.svg'
          "
          :alt="appData.businessName"
          height="30"
        />
      </a>
    </header>

    <div
      v-if="state.loading && !state.loadingMore"
      class="p-12 my-20 w-128 mx-auto text-center text-lg"
    >
      <beat-loader :color="'#436aff'"></beat-loader>
    </div>
    <template v-else-if="!state.isError && state.token">
      <main class="px-4 py-6 bg-white">
        <div class="flex flex-col space-y-4 mb-4">
          <!-- Title -->
          <div class="text-center">
            <h1 class="capitalize text-xl font-medium">{{ plans.title }}</h1>
          </div>

          <!-- Controls Row -->
          <div class="flex items-center justify-between">
            <!-- Left Section: Bulk Actions, Filters, and Selected Count -->
            <div class="flex items-center space-x-4">

              <!-- Bulk Actions -->
              <CstDropdown
                v-if="state.currentView === 'list'"
                button-classes="flex justify-between bg-main-gray gap-y-4"
                size="small"
                disabled-text="Select two or more posts to perform bulk operations"
                :disabled="state.selectedPlans.length < 2"
              >
                <template v-slot:selected> <i class="far fa-layer-group mr-2"></i> Bulk Action</template>
                <template v-slot>
                  <CstDropdownItem @click="handleBulkAction('approve')">Approve</CstDropdownItem>
                  <CstDropdownItem @click="handleBulkAction('reject')">Reject</CstDropdownItem>
                  <CstDropdownItem @click="handleBulkAction('comment')">Comment</CstDropdownItem>
                </template>
              </CstDropdown>

              <!-- Filters -->
              <CstDropdown
                container-classes="w-[200px] !left-0"
                size="small"
                button-classes="flex justify-between bg-main-gray gap-y-4"
              >
                <template v-slot:selected>
                  <i class="far fa-filter mr-2"></i> Filters
                </template>
                <template v-slot>
                  <CstDropdownItem :close-on-click='false'>
                    <div class="flex justify-between items-center">
                      <CstSimpleCheckBox
                        v-model="state.selectAll"
                        name="all"
                        value="all"
                        :reverse="true"
                      >
                        <template v-slot:label class="pt-1">All</template>
                      </CstSimpleCheckBox>
                    </div>
                  </CstDropdownItem>
                  <CstDropdownItem v-for="filter in state.filters" :key="filter.key" :close-on-click='false'>
                    <div class="flex justify-between items-center">
                      <CstSimpleCheckBox
                        v-model="state.selectedFilters"
                        :name="filter.key"
                        :value="filter.key"
                        :reverse="true"
                      >
                        <template v-slot:label class="pt-1">{{ filter.value }}</template>
                      </CstSimpleCheckBox>
                    </div>
                  </CstDropdownItem>
                </template>
              </CstDropdown>

              <div
                v-if="state.selectedPlans.length > 0 && state.currentView === 'list'"
                class="items-center rounded-xl font-normal px-8 py-1.5 text-sm flex justify-between bg-opacity-80 bg-orange-300 text-gray-900 ">
                <span class="text-sm opacity-70">{{ state.selectedPlans.length }} post(s) selected</span>
              </div>
            </div>

            <!-- Right Section: View Switcher and Count -->
            <div class="flex items-center space-x-6">
               <!-- Count -->
               <div v-if="state.currentView === 'list'" class="flex items-center">
                <span class="font-weight-500 opacity-70">
                  Showing {{ filteredPlans?.length }} of {{ plans.total }} post(s)
                </span>
              </div>
              <!-- View Switcher -->
              <div class="cst-switch">
                <div class="inline-flex justify-between items-center cursor-pointer p-1 rounded-xl bg-gray-200">
                  <a v-tooltip="'Calendar View'" @click="switchView('calendar')" class="flex items-center px-2">
                    <div
                      :class="{ 'bg-white text-primary-variant-1': state.currentView === 'calendar' }"
                      class="p-2 flex justify-center items-center rounded-md space-x-2">
                      <img
                        v-if="state.currentView === 'calendar'"
                        src="@assets/img/icons/planner/planner_header/planner-calendar-view-active.svg"
                        class="w-5 h-5"
                        alt="Active Calendar View Icon" />
                      <img
                        v-else
                        src="@assets/img/icons/planner/planner_header/planner-calendar-view.svg"
                        class="w-5 h-5"
                        alt="Inactive Calendar View Icon" />
                      <span :class="{ 'text-primary-variant-1': state.currentView === 'calendar' }" class="text-sm text-gray-900 pt-0.5" >Calendar</span>
                    </div>
                  </a>
                  <a v-tooltip="'List View'" @click="switchView('list')" class="flex items-center px-2">
                    <div
                      :class="{ 'bg-white text-primary-variant-1': state.currentView === 'list' }"
                      class="p-2 flex justify-center items-center rounded-md space-x-2">
                      <img
                        v-if="state.currentView === 'list'"
                        src="@assets/img/icons/planner/planner_header/planner-list-view-active.svg"
                        class="w-5 h-5"
                        alt="Active List View Icon" />
                      <img
                        v-else
                        src="@assets/img/icons/planner/planner_header/planner-list-view.svg"
                        class="w-5 h-5"
                        alt="Inactive List View Icon" />
                      <span :class="{ 'text-primary-variant-1': state.currentView === 'list' }" class="text-sm text-gray-900 pt-0.5">List</span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Calendar View -->
        <div
          v-if="state.currentView === 'calendar'"
          class="planner-calender-main"
        >
          <transition name="fade-list-item">
            <template v-if="!filteredPlans.length">
              <div class="warning_box text-center mx-0">
                <p>No posts found.</p>
              </div>
            </template>
          </transition>

          <FullCalendar
            class="planner-app-calendar"
            ref="external-calendar"
            :options="{
              ...state.calendarOptions,
              timeZone: plans.timezone,
              events: filterContent,
              eventClick: handleEventClick,
            }"
          >
            <template v-slot:eventContent="arg">
              <ExternalCalendarEvent
                :event="arg.event"
                :time-zone="plans.timezone"
                :type="
                arg.event.extendedProps?.common_box_status !== undefined
                  ? 'plan'
                  : 'note'"
                @on-lightbox="onLightbox"
                @preview-plan="previewPlan"
              />
            </template>
          </FullCalendar>
        </div>

        <!-- List View -->
        <div v-else>
          <div
            v-dragscroll
            class="
              f-table
              bg-white
              w-full
              shadow-sm
              select-none
              text-sm text-left text-gray-900
              overflow-auto
            "
            @scroll="handleScroll"
          >
            <!--   Header   -->
            <div
              class="
                flex flex-nowrap flex-row
                sticky
                top-0
                w-full
                bg-[#f3f4f4]
                z-20
              "
            >
              <div
                class="
                  f-table-cell
                  bg-[#f3f4f4]
                  !w-[50px]
                  px-6
                  py-3
                  cursor-pointer
                  capitalize
                  font-weight-500
                  sticky
                  left-0
                  flex
                  items-center
                "
              >
                <CstSimpleCheckBox
                  type="checkbox"
                  :checked="isAllSelected"
                  @change="toggleSelectAll"
                />
              </div>

              <div
                class="
                  f-table-cell
                  bg-[#f3f4f4]
                  !w-[400px]
                  px-6
                  py-3
                  cursor-pointer
                  capitalize
                  font-weight-500
                  sticky
                  left-0
                  flex
                  items-center
                "
              >
                Post
              </div>
              <div
                class="
                  f-table-cell
                  bg-[#f3f4f4]
                  px-6
                  py-3
                  cursor-pointer
                  capitalize
                  font-weight-500
                "
              >
                Accounts
              </div>
              <div
                class="
                  f-table-cell
                  bg-[#f3f4f4]
                  px-6
                  py-3
                  cursor-pointer
                  capitalize
                  font-weight-500
                "
              >
                Status
              </div>
              <div
                class="
                  f-table-cell
                  bg-[#f3f4f4]
                  px-6
                  py-3
                  cursor-pointer
                  capitalize
                  font-weight-500
                "
              >
                My Feedback
              </div>
              <div
                class="
                  f-table-cell
                  bg-[#f3f4f4]
                  px-6
                  py-3
                  cursor-pointer
                  capitalize
                  font-weight-500
                "
              >
                Created By
              </div>
              <div
                class="
                  f-table-cell
                  bg-[#f3f4f4]
                  px-6
                  py-3
                  cursor-pointer
                  capitalize
                  font-weight-500
                "
              >
                Created At
              </div>
              <div
                class="
                  f-table-cell
                  bg-[#f3f4f4]
                  px-6
                  py-3
                  cursor-pointer
                  capitalize
                  font-weight-500
                "
              >
                Last Updated At
              </div>
              <!--    Actions      -->
              <div
                  class="f-table-cell bg-[#f3f4f4] px-6 py-3 cursor-pointer capitalize font-weight-500 !w-[120px] flex-1"
              ></div>
            </div>

            <!--   Body   -->
            <div>
              <!-- NO DATA -->
              <div
              v-if="!filteredPlans.length"
              :style="{
              width: '100%',
              marginTop: '50px',
            }"
              class="flex justify-center h-full fixed"
          >
            <ContentAvailableState :type="'no_results'" class="m-0">
              <template v-slot:headline>
                <h2>No posts found</h2>
              </template>
              <template v-slot:text>
                <p>No posts found for the selected filters</p>
              </template>
            </ContentAvailableState>
          </div>
               <DataRow
                v-for="(plan, i) in filteredPlans"
                v-else
                :key="plan._id"
                :plan="plan"
                :selected="state?.selectedPlans?.includes(plan._id)"
                :timezone="plans?.timezone"
                @on-plan-select="onPlanSelect"
                @on-plan-status-click="onPlanStatusClick"
                @on-lightbox="onLightbox"
                @preview-plan="previewPlan"
              />

              <!--    Load More      -->
              <div
                v-if="state.loadingMore"
                class="flex flex-nowrap flex-row w-full f-table-row"
              >
                <div class="f-table-cell px-6 py-4 sticky left-0">
                  <div class="flex items-center text-base">
                    <i class="fad fa-spinner-third fa-spin"></i>
                    <span class="ml-3">Loading more...</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </template>
    <template v-else>
      <div
        class="
          rounded-xl
          bg-white
          shadow-sm
          p-12
          my-20
          w-128
          mx-auto
          text-center text-lg
        "
      >
        The link is invalid or disabled by the user.
      </div>
    </template>

    <PublicPasswordProtectedModal
      :link-id="state.linkId"
      @on-verify="onVerify"
    />

    <ExternalActionsModal :token="state.token" />

    <VueEasyLightbox
      :visible="lightBox.imageVisible"
      :imgs="lightBox.media"
      @hide="handleHide"
    ></VueEasyLightbox>

    <DisplayFile></DisplayFile>

    <b-modal
      id="post-status-modal"
      size="lg"
      centered
      :no-close-on-backdrop="true"
      hide-header
      hide-footer
      dialog-class="max-w-7xl"
    >
      <PlannerPostStatus
        v-if="state.focusedPlan"
        :item="state.focusedPlan"
        modal-id="post-status-modal"
      />
    </b-modal>

    <PlannerPostPreview
      :plan="state.currentPlanInPreview"
      :token="state.token"
      :disable-left="planPreviewDisableLeft()"
      :disable-right="planPreviewDisableRight()"
      :external-post-preview="true"
      @next-plan="previewNextPlan"
      @previous-plan="previewPreviousPlan"
      @approve-with-comment="approveWithComment"
      @reject-with-comment="rejectWithComment"
    />
  </div>
</template>

<script>
import { defineComponent, inject, onMounted, reactive, computed, watch, ref } from 'vue'
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import momentTimezonePlugin from '@fullcalendar/moment-timezone'
import ExternalCalendarEvent from '@src/modules/planner_v2/components/ExternalCalendarEvent'
import { dragscroll } from 'vue-dragscroll'
import PublicPasswordProtectedModal from '@src/modules/planner_v2/components/PublicPasswordProtectedModal'
import { UNKNOWN_ERROR } from '@common/constants/messages'
import {
  ACTION_TYPES,
  serviceShareLink,
} from '@src/modules/planner_v2/services'
import DataRow from '@src/modules/planner_v2/components/DataRow'
import ExternalActionsModal from '@src/modules/planner_v2/components/ExternalActionsModal'
import VueEasyLightbox from 'vue-easy-lightbox'
import { EventBus } from '@common/lib/event-bus'
import DisplayFile from '@src/modules/publish/components/dialogs/DisplayFile'
import PlannerPostStatus from '@src/modules/planner_v2/components/PlannerPostStatus'
import PlannerPostPreview from '@src/modules/planner_v2/components/PlannerPostPreview_v2.vue'
import { useStore } from '@state/base'
import useWhiteLabelApplication from '@modules/setting/composables/whitelabel/useWhiteLabelApplication'
import CstSimpleCheckBox from '@ui/CheckBox/CstSimpleCheckbox.vue'
import CstDropdown from '@ui/Dropdown/CstDropdown.vue'
import CstDropdownItem from '@ui/Dropdown/IconDropdownItem.vue'
import {sanitizeUrl} from "@braintree/sanitize-url";
import {formatNotesDate, parseDescriptionHtml} from '@common/lib/helper';

export default defineComponent({
  name: 'SharePlans',
  components: {
    CstDropdownItem,
    CstDropdown,
    PlannerPostPreview,
    PublicPasswordProtectedModal,
    DataRow,
    ExternalActionsModal,
    VueEasyLightbox,
    DisplayFile,
    PlannerPostStatus,
    FullCalendar,
    ExternalCalendarEvent,
    dayGridPlugin,
    interactionPlugin,
    momentTimezonePlugin,
    CstSimpleCheckBox,
  },
  directives: {
    dragscroll,
  },
  emits: ['init-complete'],
  setup(props, {emit}) {
    const store = useStore();

    const fetchLabels = async () => {
      try {
        await store.dispatch('fetchLabelsForGuest', plans?.data[0]?.workspace_id);
      } catch (error) {
        console.error('Error fetching labels:', error);
      }
    };

    const root = inject('root')
    const { $bvModal, $route } = root
    const { dispatch } = useStore()
    const { appData, isWhiteLabelDomain } = useWhiteLabelApplication()

    const location = window.location?.origin
    const state = reactive({
      linkId: '',
      isPasswordProtected: false,
      isError: false,
      loading: false,
      loadingMore: false,
      token: '',
      selectedAll: false,
      selectedPlans: [],
      focusedPlan: {},
      currentPlanIndex: -1,
      currentPlanInPreview: {},
      currentView: 'list',
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, momentTimezonePlugin],
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,dayGridWeek',
        },
        editable: false,
        dayMaxEvents: 10,
        weekends: true,
        contentHeight: 'auto',
        expandRows: false,
        dayHeaderFormat: { weekday: 'long' },
        views: {
          week: {
            dayHeaderFormat: {
              weekday: 'long',
              month: 'numeric',
              day: 'numeric',
            },
          },
          day: {
            dayHeaderFormat: {
              weekday: 'long',
              month: 'numeric',
              day: 'numeric',
            },
          },
        },
        firstDay: 1,
      },
      showBulkActions: false,
      selectAll: false,
      selectedFilters: [],
      filters: [
          { key: 'approved', value: 'Approved' },
          { key: 'rejected', value: 'Rejected' },
          { key: 'commented', value: 'Commented' },
      ],
    })

    const plans = reactive({
      title: '',
      data: [],
      page: 1,
      total: 0,
      isNextPageExists: false,
    })

    const notes = ref([])

    const lightBox = reactive({
      imageVisible: false,
      videoVisible: false,
      media: [],
    })


    const getCurrentUser = () => {
      // check local storage for email
      return  localStorage.getItem('external_email') || ''
    }

    const handleEventClick = (info) => {
      const target = info.jsEvent.target
      const event = target.getAttribute('data-event')
      const item = JSON.parse(JSON.stringify(info.event.extendedProps))

      console.log('handleEventClick', item)

      if(!event && item.common_box_status !== undefined) {
      } else {
        const noteElement = target.closest('div[id$="_note_popover"]');
        const elementId = noteElement ? noteElement.id : null;

        console.log('handleEventClick', elementId)

        const popover = createPopover(
            info.el,
            info.event.title,
            info.event.extendedProps,
            elementId,
            {
              isLastColumn: info.el.fcSeg.isEnd,
              column: info.el.fcSeg.lastCol,
              totalColumns: 7
            }
        )
      }

    }


    const createPopover = (eventEl, title, item, elementId, placement = {}) => {
      const popover = document.createElement('div')
      popover.className = 'popover notes-popup note-popup-open bg-transparent w-[28rem] mx-auto absolute z-10 border-0'

      const combinedContent = `${item.note_title} ${item.description}`;
      const contentHeight = combinedContent.length > 230 ? 'max-height: 18rem;' : '';

      // Define fallback image URL
      const fallbackImageUrl = 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg';

      // Function to handle image error
      const handleImageError = (imgElement) => {
        imgElement.onerror = null; // Prevent infinite loop
        imgElement.src = fallbackImageUrl;
      };

      popover.innerHTML = `<div class="relative bg-transparent w-[28rem] mx-auto mb-20">
        <div id="caret" class="absolute top-[-24px] left-[25px] transform -translate-x-1/2">
          <i style="color: ${item.note_color}" class="fa fa-solid fa-caret-up text-4xl"></i>
        </div>
        <div class="rounded-lg shadow-md bg-white" style="border-top: 4px solid ${item.note_color}">
          <div style="border-bottom: 1px solid #e3e3e3" class="flex justify-between p-4">
            <div class"header">
              <h3 class="text-[0.9rem] mb-1 pr-3 pt-1.5 text-[#3A4557]">
                <strong>Note</strong> -
                <span class="text-[#757A8A] text-normal">
                    ${item.start_date === item.end_date
                      ? formatNotesDate(item.start_date, plans.timezone)
                      : `${formatNotesDate(item.start_date, plans.timezone)} - ${formatNotesDate(item.end_date, plans.timezone)}`
                    }
                </span>
              </h3>
            </div>
            <div class="close-button">
            <button id="closepopover" title="Close" class="btn text-gray-400 hover:shadow px-2 py-0.5 bg-[#3A45570D]">
              <i class="font-light fas fa-times"></i>
            </button>
            </div>
          </div>


            <div class="overflow-y-auto" style="${contentHeight}">
            <div class="p-4">
              <h3 class="text-[0.9rem] mb-1 pr-3 text-[#3A4557]"><strong>${item.note_title}</strong></h3>
              <p class="text-sm text-[#757A8A] pb-4">${parseDescriptionHtml(item.description,item?.description?.length, true, false)}</p>
            </div>
          </div>

          <div style="border-top: 1px solid #e3e3e3" class="flex items-center justify-between pl-[10px] pr-5 pt-3 pb-4">
            <div title="${item.author}" class="flex items-center">
              <img
                src='${item.author_image || fallbackImageUrl}'
                alt="Author"
                class="rounded-full border mr-2 w-8 h-8"
                onerror="this.onerror=null; this.src='${fallbackImageUrl}';"
              >
              <span class="truncate inline-block max-w-[7rem] pt-1 lg:max-w-[9rem] text-sm text-[#757A8A]">By: <strong class="font-medium capitalize text-[#3A4557]">${item.author}</strong></span>
            </div>
          </div>
        </div>
      </div>`

      // Add image error handling after popover is created
      const authorImage = popover.querySelector('img[alt="Author"]');
      if (authorImage) {
        authorImage.addEventListener('error', () => handleImageError(authorImage));
      }

      // Append popover to the calendar container instead of body
      const note_popover = document.getElementById(elementId)
      note_popover.appendChild(popover)

      // Function to close popover
      const closePopover = () => {
        if (popover.parentNode) {
          popover.parentNode.removeChild(popover)
          // Remove the click event listener when popover is closed
          document.removeEventListener('mousedown', handleOutsideClick)
        }
      }


      // Handle clicks outside the popover
      const handleOutsideClick = (event) => {
        // Check if click is outside both the popover and the triggering event element
        if (!popover.contains(event.target) &&
            !note_popover.contains(event.target)) {
          closePopover()
        }
      }

      document.addEventListener('mousedown', handleOutsideClick)

      const closeBtn = popover.querySelector('#closepopover')
      closeBtn.addEventListener('click', () => {
        if (popover.parentNode) {
          popover.parentNode.removeChild(popover)
        }
      })

      // Set initial position
      popover.style.position = 'absolute';
      popover.style.left = '20px';
      popover.style.top = '46px';

      console.log('createPopover', placement)

      // Get measurements after initial positioning
      const viewportWidth = window.innerWidth;
      const margin = 20; // Safe margin from viewport edge

      // Check if popover is overflowing viewport
      if (placement.column >= placement.totalColumns - 3) {
        // Set a fixed distance from the right edge
        const rightOffset = '13rem'; // 10rem in pixels

        popover.style.left = 'auto';
        popover.style.right = rightOffset;

        // Adjust caret position
        const caret = popover.querySelector('#caret');
        if (caret) {
          caret.style.left = 'auto';
          caret.style.right = '0px';
        }

        // Add max-width for smaller screens
        if (viewportWidth < 768) { // For screens smaller than tablet
          popover.style.maxWidth = `${viewportWidth - margin * 2}px`;
        }
      }


      return popover
    }

    // Handle the select all checkbox
    watch(
      () => state.selectAll,
      (newVal) => {
        // Prevent unnecessary updates
        if (newVal === (state.selectedFilters.length === state.filters.length)) {
          return;
        }

        // Update selected filters based on selectAll state
        state.selectedFilters = newVal ? [...state.filters.map(f => f.key)] : [];
      }
    );

    // Watch selected filters to sync with selectAll state
    watch(
      () => state.selectedFilters,
      (newVal, oldVal) => {
        // Skip if arrays are the same
        if (newVal === oldVal) return;

        // Get all available filter keys except 'all'
        const availableFilters = state.filters.map(f => f.key);

        // Check if all filters are selected
        const hasAllFilters = availableFilters.every(key => newVal.includes(key));

        // Update selectAll without triggering its watcher
        if (hasAllFilters !== state.selectAll) {
          state.selectAll = hasAllFilters;
        }
      },
      { deep: true }
    );

    const filteredPlans = computed(() => {
      // If no filters selected, return all plans
      if (state.selectedFilters.length === 0 || state.selectAll) {
        return plans.data;
      }

      // Filter plans based on selected filters
      return plans.data.filter((plan) => {
        return state.selectedFilters.some((filter) => {
          switch (filter) {
            case 'approved':
              return plan?.external_actions?.some(action =>
                action.type === 'approve' && action.email === getCurrentUser()
              );

            case 'rejected':
              return plan?.external_actions?.some(action =>
                action.type === 'reject' && action.email === getCurrentUser()
              );

            case 'commented':
              return plan?.external_comments?.some(comment =>
                comment.email === getCurrentUser()
              );

            default:
              return false;
          }
        });
      });
    });

    const isAllSelected = computed(() => {
      return (
        filteredPlans.value.length > 0 &&
        state.selectedPlans.length === filteredPlans.value.length
      )
    })

    const filterContent = computed(() => {
      return [
        ...notes.value.filter(note => note.is_private === false).map(note => ({
          ...note,
          startEditable: false,
          end: note.start_date !== note.end_date ? new Date(new Date(note.end_date).setHours(23, 59, 59, 999)) : null
        })),
        ...filteredPlans.value
      ]
    })

    const toggleSelectAll = () => {
      if (isAllSelected.value) {
        state.selectedPlans = []
      } else {
        state.selectedPlans = filteredPlans.value.map((plan) => plan._id)
      }
      state.showBulkActions = state.selectedPlans.length > 0
    }

    const handleFilterChange = (filters) => {
      state.filters = filters
      state.selectedPlans = [] // Clear selections when filter changes
      state.showBulkActions = false
    }

    const switchView = (view) => {
      state.currentView = view

      // Reset states and fetch plans
      plans.data = []
      plans.page = 1
      plans.total = 0
      plans.isNextPageExists = false
      fetchPlans()
    }

    /**
     * Returns true/false if the previous button should be disabled.
     * @returns {boolean}
     */
    const planPreviewDisableLeft = () => {
      return state.currentPlanIndex === 0
    }

    /**
     * Returns true/false if the next button should be disabled.
     * @returns {boolean}
     */
    const planPreviewDisableRight = () => {
      return state.currentPlanIndex === filteredPlans.value.length - 1
    }

    onMounted(async () => {
      state.linkId = $route.params.id

      if (!state.linkId) {
        state.isError = true
        await dispatch('toastNotification', {
          message: 'The provided link is invalid',
          type: 'error',
        })
      }

      await getShareLink(state.linkId)

      EventBus.$on('post-status-modal', (post) => {
        onPlanStatusClick(post)
      })

      EventBus.$on('external-actions::complete', async () => {
        // Reset selected plans
        state.selectedPlans = []
        state.showBulkActions = false

        // Reset page state and refetch plans
        plans.data = []
        plans.page = 1
        plans.total = 0
        plans.isNextPageExists = false

        await fetchPlans()
      })
    })

    /**
     * Method to handle scroll event
     */
    const handleScroll = () => {
      const element = document.querySelector('.f-table')
      if (element) {
        const { scrollTop, scrollHeight, clientHeight } = element
        const buffer = 10 // Adjust the buffer value as needed

        if (scrollTop + clientHeight >= scrollHeight - buffer) {
          fetchNextPage()
        }
        console.log('At Bottom')
      }
    }

    // Attach the event listener to

    /**
     * Method to get share link
     */
    const getShareLink = async (id) => {
      state.loading = true
      try {
        const response = await serviceShareLink(ACTION_TYPES.GET_LINK, {
          link_id: id,
        })
        plans.title = response?.name
        if (response.is_password_protected) {
          state.isPasswordProtected = true
          $bvModal.show('public-password-protected')
        } else {
          state.token = response.token
        }
      } catch (error) {
        state.isError = true
        await dispatch('toastNotification', {
          message: error.message || UNKNOWN_ERROR,
          type: 'error',
        })
      } finally {
        state.loading = false

        if (state.token) {
          await fetchPlans()
        }
      }
    }

    /**
     * Method to verify password
     */
    const onVerify = (token) => {
      state.token = token
      $bvModal.hide('public-password-protected')

      fetchPlans()
    }

    /**
     * Method to fetch plans
     */
    const fetchPlans = async (page = 1) => {
      if (state.loading) return
      state.loading = true
      state.loadingMore = page > 1

      try {
        const response = await serviceShareLink(ACTION_TYPES.FETCH_PLANS, {
          token: state.token,
          page,
          limit: state.currentView === 'calendar' ? 500 : 10,
        })
        if (plans.data.length) {
          plans.data = [...plans.data, ...response.plans.data]
          state.selectedAll = false
        } else {
          plans.data = response.plans.data
        }

        notes.value = response.notes
        plans.page = response.plans.current_page
        plans.total = response.plans.total
        plans.isNextPageExists = response.plans.next_page_url !== null
        plans.timezone = response.timezone

        // after we have the required data, fetch labels and campaign details
        fetchLabels()
        emit('init-complete')
      } catch (error) {
        await dispatch('toastNotification', {
          message: error.message || UNKNOWN_ERROR,
          type: 'error',
        })
      } finally {
        state.loading = false
        state.loadingMore = false
      }
    }

    /**
     * Method to fetch next page
     */
    const fetchNextPage = () => {
      if (plans.isNextPageExists) {
        fetchPlans(plans.page + 1)
      }
    }

    /**
     * Method to select all plans
     */
    const selectAll = () => {
      if (state.selectedAll) {
        state.selectedPlans = plans.data.map((plan) => plan._id)
      } else {
        state.selectedPlans = []
      }
    }

    /**
     * Method to select plan
     */
    const onPlanSelect = (planId) => {
      if (state.selectedPlans.includes(planId)) {
        state.selectedPlans = state.selectedPlans
          .filter((id) => id !== planId)
          .map((id) => id)
      } else {
        state.selectedPlans.push(planId)
      }

      state.selectedAll = state.selectedPlans.length === plans.data.length
    }

    const getActionablePlans = (selectedPlans, action) => {
      const allowedStatuses = ['scheduled', 'draft', 'in review', 'missed review']
      const actionablePlans = plans.data
        .filter(plan => selectedPlans.includes(plan._id) && allowedStatuses.includes(plan.render_class?.tooltip?.toLowerCase()))
        .map(plan => plan._id)

      return actionablePlans
    }

    const handleBulkAction = (action) => {
      if (!state.selectedPlans.length) {
        dispatch('toastNotification', {
          message: 'Please select at least one post',
          type: 'warning'
        })
        return
      }

      const actionablePlans = getActionablePlans(state.selectedPlans, action)

      if (actionablePlans.length === 0 && action !== 'comment') {
        dispatch('toastNotification', {
          message: 'No eligible posts selected. Only scheduled, draft, in review, and missed review posts can be approved or rejected.',
          type: 'warning'
        })
        return
      }

      if (action !== 'comment' && actionablePlans.length < state.selectedPlans.length) {
        dispatch('toastNotification', {
          message: `Only ${actionablePlans.length} of ${state.selectedPlans.length} selected posts can be ${action}d. Published and failed posts are excluded.`,
          type: 'info'
        })
      }

      switch (action) {
        case 'approve':
          EventBus.$emit('external-actions::show', {
            type: 'approve',
            plans: actionablePlans
          })
          break

        case 'reject':
          EventBus.$emit('external-actions::show', {
            type: 'reject',
            plans: actionablePlans
          })
          break

        case 'comment':
          EventBus.$emit('external-actions::show', {
            type: 'comment',
            plans: state.selectedPlans // Comments can be added to any post
          })
          break
      }
    }

    const handleHide = () => {
      lightBox.imageVisible = false
    }

    /**
     * Method to show lightbox
     * @param type - type of media, can be image or video
     * @param media Array of urls with type image and single url with type video
     */
    const onLightbox = (type = 'image', media) => {
      if (type === 'image') {
        lightBox.imageVisible = true
        lightBox.media = media
      } else {
        lightBox.videoVisible = true
        EventBus.$emit('displayFile', {
          type,
          media,
          index: 0,
        })
        $bvModal.show('display-file-modal')
      }
    }

    /**
     * Method to show plan status
     * @param plan
     */
    const onPlanStatusClick = (plan) => {
      state.focusedPlan = plan
      $bvModal.show('post-status-modal')
    }

    const previewPlan = (plan) => {
      state.focusedPlan = plan
      const planIndex = filteredPlans.value.findIndex(
        (item) => item._id === state.focusedPlan._id
      )
      state.currentPlanIndex = planIndex
      state.currentPlanInPreview = filteredPlans.value[planIndex]
      $bvModal.show('planner-post-preview')
    }

    /**
     * onClick event for previewing next plan
     */
    const previewNextPlan = () => {
      if (state.currentPlanIndex === filteredPlans.value.length - 1) return
      state.currentPlanInPreview = filteredPlans.value[++state.currentPlanIndex]
    }

    /**
     * onClick event for previewing previous plan
     */
    const previewPreviousPlan = () => {
      if (state.currentPlanIndex === 0) return
      state.currentPlanInPreview = filteredPlans.value[--state.currentPlanIndex]
    }

    /**
     * Event for displaying the approve modal
     * @param item
     */
    const approveWithComment = (item) => {
      console.log('METHOD::approveWithComment ~ item -> ', item)
      state.planForApproval = item
      EventBus.$emit('external-actions::show', {
        type: 'approve',
        plans: state.planForApproval,
      })
    }

    /**
     * Event for displaying the reject modal
     * @param item
     */
    const rejectWithComment = (item) => {
      console.log('METHOD::rejectWithComment ~ item -> ', item)
      state.planForReject = item
      EventBus.$emit('external-actions::show', {
        type: 'reject',
        plans: state.planForReject,
      })
    }

    return {
      plans,
      notes,
      state,
      lightBox,
      appData,
      isWhiteLabelDomain,
      location,
      onVerify,
      fetchNextPage,
      selectAll,
      onPlanSelect,
      handleScroll,
      handleBulkAction,
      handleEventClick,
      handleHide,
      onLightbox,
      onPlanStatusClick,
      previewPlan,
      previewNextPlan,
      previewPreviousPlan,
      approveWithComment,
      rejectWithComment,
      planPreviewDisableRight,
      planPreviewDisableLeft,
      switchView,
      isAllSelected,
      handleFilterChange,
      toggleSelectAll,
      filteredPlans,
      filterContent
    }
  },
  mounted() {
    this.$once('init-complete', () => {
      this.fetchPublicationFoldersForGuests(this.plans.data[0]?.workspace_id);
    });
  },
  methods: {sanitizeUrl},
})
</script>

<style lang="scss" scoped>
.f-table {
  border: 1px solid #eee;
  border-radius: 0.375rem;

  // 100vh - 60px (header) - 3rem (top + bottom paddings) - 2.75rem (Bulk Actions) - 1rem (Bulk Action top + bottom margins)
  height: calc(100vh - 60px - 6rem - 2.75rem - 1rem); // to prevent scroll - updated values
}

.f-table-cell {
  @apply w-[200px] flex-shrink-0;

  @media (max-width: 1280px) {
    @apply w-[200px];
  }
}

.f-table-cell-center-y {
  @apply flex items-center;
}


:deep(.fc-h-event) {
  border: none !important;
  background-color: transparent !important;
}

</style>
